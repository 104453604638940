/************* Leave ************/

.leave-type-section {
  padding: 8px;
  background: #166ab333;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leave-type-section svg {
  width: 20px;
}

.leave-days {
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  display: flex;
}

.leave-type {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  float: left;
  margin-right: 4px;
}

.leave-action-section {
  color: #166ab3;
  padding: 8px 8px 0px 8px;
  height: 68px;
  color: #166ab3;
}

.leaveBalance-title {
  color: #166ab3;
  font-size: 16px;
  font-weight: 600;
}

.leave-name {
  position: relative;
  margin-left: 4px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.leaverequest-head {
  padding: 16px;
  background: #166ab333;
}

.leavebalancehead {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.87);
}

.leavebalance-drawer {
  padding: 15px;
  width: 93%;
  background: white;
  font-size: 16px;
  min-height: 100vh;
  overflow-x: hidden;
  .e-input-group.e-control-wrapper.e-date-range-wrapper input {
    padding: 10px;
    font-size: 16px;
    color: #000;
    z-index: 0 !important;
  }
}

.leavebalance-drawer-ledger {
  padding: 15px;
  width: 98%;
  background: white;
  font-size: 16px;
  max-height: 94vh;
  overflow-x: hidden;
  overflow-y: auto;
  .e-input-group.e-control-wrapper.e-date-range-wrapper input {
    padding: 10px;
    font-size: 16px;
    color: #000;
    z-index: 0 !important;
  }
}

.leavebalance-drawer textarea {
  width: 91%;
  padding: 16px;
  font-size: 16px;
}

.history-leave-margin {
  margin-top: 8px;
  margin: 0;
}

.annual-creditleave {
  padding: 8px 8px;
  color: #010709;
  height: 32px;
  border-bottom: 1px solid #ddd;
}

.annual-debitleave {
  padding: 8px 8px;
  color: #010709;
  height: 32px;
  border-bottom: 1px solid #ddd;
}

.annual-total-balance {
  color: #166ab3;
  float: left;
  margin-left: 32px;
  margin-top: 12px;
}

.annual-total-leaves {
  float: left;
  color: #18aca7;
  margin-top: 12px;
}

.leave-creadit-expand-button:hover,
.MuiIconButton-root-47:hover {
  background-color: transparent !important;
}

.leave-creadit-expand {
  padding: 0px 8px;
  background: #fff;
  font-size: 14px;
}

.leave-creadit-textarea {
  width: 100% !important;
  margin-top: 15px;
}

.data-value {
  font-size: 14px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.data-count {
  font-size: 14px;
  margin-right: 32px;
  float: right;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.annual-leave-balance {
  margin-bottom: 16px;
  margin-left: 10px;
  display: inline-block !important;
}
