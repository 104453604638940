.thumbnail-big {
  height: 140px;
  width: 100px;
  object-fit: cover;
  border-radius: 4px;
}

.thumbnail-big-rectangle {
  height: 64px;
  width: 100%;
  object-fit: scale-down;
  border-radius: 4px;
}

.thumbnail-big-wrapper {
  height: 140px;
  width: 100px;
  margin: 4px;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background: #f9f9f9;
}
.thumbnailbig-deleteicon {
  position: absolute;
  right: 4px;
  bottom: 4px;
}
.thumbnail-big-iconwrapper {
  position: relative;
  top: -144px;
  text-align: center;
  opacity: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}

.thumbnail-big-iconwrapper-rect {
  position: absolute;
  top: 0;
  text-align: center;
  opacity: 0;
  height: 32px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  width: 100%;
  padding: 16px 0px;
}

.icon-wrapper-circular {
  padding: 4px;
  border-radius: 16px;
  height: 32px;
  width: 32px;
}
.thumbnail-big-wrapper:hover .thumbnail-big-iconwrapper {
  opacity: 1;
}

.thumbnail-big-wrapper:hover .thumbnail-big-iconwrapper-rect {
  opacity: 1;
}

.thumbnail-big-iconwrapper span {
  position: relative;
  bottom: -100px;
}
.thumbnail-big-pdficon {
  margin-top: 40px;
  margin-right: 4px;
  font-size: 42px;
}
.thumbnail-big-othersicon {
  margin-top: 40px;
  font-size: 42px;
}
.thumbnail-big-wrapper2 {
  height: 140px;
  width: 100px;
  margin: 4px;
  cursor: pointer;
}
.top-140 {
  top: -140px !important;
}
.thumbnail-tooltip {
  max-width: 300px;
  height: 140px;
  max-height: 500px !important;
}

.thumbnail-tooltip-rectangle {
  width: 100%;
  height: 64px;
  max-height: 64px !important;
  border-radius: 4px;
  position: relative;
}

.edit-icon-wrapper {
  width: 44%;
  display: inline-block;
  text-align: right;
  padding-right: 4px;
}

.delete-icon-wrapper {
  width: 44%;
  display: inline-block;
  text-align: left;
  padding-left: 4px;
}

.profile-picture-custom {
  border-radius: 50%;
  vertical-align: middle;
}

.profile-picture-extra-small {
  height: 24px;
  width: 24px;
  border-radius: 40px;
  vertical-align: middle;
}

.profile-picture {
  height: 32px;
  width: 32px;
  border-radius: 30px;
}

.profile-picture-medium {
  height: 48px;
  width: 48px;
  border-radius: 40px;
}
