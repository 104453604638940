/********* dashboard ************/

.subcription-count {
  font-size: 61px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #166ab3;
}

.subcription-date {
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #166ab3;
  margin-top: 10px;
}

.subcription-payment-head {
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin-top: 10px;
}

.sucess-gif {
  text-align: center;
  img {
    height: 316px;
  }
}

.processbar-status {
  font-size: 16.3px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.72;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
}

.processbar-section {
  position: fixed;
  bottom: 10px;
  width: 45%;
  margin: 0 auto;
  right: 0;
  left: 0;
}

.trialbutton {
  width: 150px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(0, 0, 0, 0.57);
  text-transform: uppercase;
  border-radius: 6px;
}

.tabheading span {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-transform: capitalize;
  line-height: 1.75;
  letter-spacing: 0.5px;
  text-align: left;
}

.invite-label span{
  text-transform: none !important;
}

.dashboard-floatting-button {
  position: fixed;
  right: 40px;
  bottom: 40px;
}

.left-navigation {
  position: fixed !important;
  width: 64px;
  height: 100vh;
  z-index: 1300;
  background-color: #f7f8f8;
}