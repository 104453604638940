button {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.12;
  letter-spacing: 1.3px;
}

.hide-button {
  display: none;
  pointer-events: none;
}

.delete-btn {
  background: #ffe2e5 !important;
  color: #f60038 !important;
  border: 1px solid #ffe2e5 !important;
  box-shadow: none !important;
  margin-right: 16px !important;
}

.delete-btn:hover {
  background: #fff !important;
}

.btn-basic {
  background: transparent !important;
  color: rgba(1, 7, 9, 0.7) !important;
  border: none;
  box-shadow: none !important;
  font-weight: 700 !important;
}

.btn-basic:hover {
  color: rgba(1, 7, 9, 1) !important;
}

.btn-primary {
  background: #1e8ced !important;
  color: #fff !important;
  box-shadow: none !important;
  font-weight: 700 !important;
}

.btn-primary:hover {
  background: #035ec1 !important;
}

.btn-secondary {
  background: rgba(1, 7, 9, 0.7) !important;
  color: #fff !important;
  box-shadow: none !important;
  font-weight: 700 !important;
}

.btn-secondary:hover {
  background: rgba(1, 7, 9, 0.8) !important;
}

.success-btn {
  background-color: #008985 !important;
  color: #fff !important;
  box-shadow: none !important;
  font-weight: 700 !important;
}

.success-btn:hover {
  background: #fff !important;
}

.btn {
  color: #fff !important;
  box-shadow: none !important;
  font-weight: bold !important;
  font-family: "muli", sans-serif !important;
  padding: 6px 16px !important;
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
}

.btn-trans {
  color: black !important;
  // box-shadow: none !important;
  background: transparent !important;
  font-weight: bold !important;
  font-family: "muli", sans-serif !important;
  padding: 6px 16px !important;
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
}

.btn-trans:hover {
  background-color: #edf5fd !important;
}

.btn-trans-red {
  color: black !important;
  // box-shadow: none !important;
  background: transparent !important;
  font-weight: bold !important;
  font-family: "muli", sans-serif !important;
  padding: 8px 16px !important;
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
}

.btn-trans-red:hover {
  background-color: #ffe2e5 !important;
}

.btn-primary-lg-blue {
  background-color: #1e8ced !important;
  font-size: 19px !important;
  height: 48px;
}

.btn-primary-lg-blue:hover {
  background-color: #166ab3 !important;
}

.btn-primary-lg-green {
  background-color: #18aca7 !important;
  font-size: 19px !important;
  height: 48px;
}

.btn-primary-lg-green:hover {
  background-color: #008985 !important;
}

.btn-primary-lg-yellow {
  background-color: #faa500 !important;
  font-size: 19px !important;
  height: 48px;
}

.btn-primary-lg-yellow:hover {
  background-color: #f86b07 !important;
}

.btn-primary-lg-red {
  background-color: #ff4052 !important;
  font-size: 19px !important;
  height: 48px;
}

.btn-primary-lg-red:hover {
  background-color: #f60038 !important;
}

.btn-primary-lg-grey {
  background-color: rgba(1, 7, 9, 0.35) !important;
  font-size: 19px !important;
  height: 48px;
  cursor: default !important;
  color: rgba(255, 255, 255, 0.35) !important;
}

.btn-secondary-lg-blue {
  background-color: #edf5fd !important;
  border: solid 1px #edf5fd !important;
  font-size: 19px !important;
  color: #166ab3 !important;
  height: 48px;
}

.btn-secondary-lg-blue:hover {
  border: solid 1px #166ab3 !important;
}

.btn-secondary-lg-green {
  background-color: #dcf2f1 !important;
  border: solid 1px #dcf2f1 !important;
  font-size: 19px !important;
  color: #008985 !important;
  height: 48px;
}

.btn-secondary-lg-green:hover {
  border: solid 1px #008985 !important;
}

.btn-secondary-lg-yellow {
  background-color: #fef1d9 !important;
  border: solid 1px #fef1d9 !important;
  font-size: 19px !important;
  color: #f86b07 !important;
  height: 48px;
}

.btn-secondary-lg-yellow:hover {
  border: solid 1px #f86b07 !important;
}

.btn-secondary-lg-red {
  background-color: #ffe2e5 !important;
  border: solid 1px #ffe2e5 !important;
  font-size: 19px !important;
  color: #f60038 !important;
  height: 48px;
}

.btn-secondary-lg-red:hover {
  border: solid 1px #f60038 !important;
}

.btn-primary-md-blue {
  background-color: #1e8ced !important;
  font-size: 16px !important;
  height: 40px;
  line-height: 18px !important;
}

.btn-primary-md-blue:hover {
  background-color: #166ab3 !important;
}

.btn-primary-md-green {
  background-color: #18aca7 !important;
  font-size: 16px !important;
  height: 40px;
  text-transform: none !important;
}

.btn-primary-md-green:hover {
  background-color: #008985 !important;
}

.btn-primary-md-yellow {
  background-color: #faa500 !important;
  font-size: 16px !important;
  height: 40px;
}

.btn-primary-md-yellow:hover {
  background-color: #f86b07 !important;
}

.btn-primary-md-red {
  background-color: #ff4052 !important;
  font-size: 16px !important;
  height: 40px;
}

.btn-primary-md-red:hover {
  background-color: #f60038 !important;
}

.btn-primary-md-grey {
  background-color: rgba(1, 7, 9, 0.35) !important;
  font-size: 16px !important;
  height: 40px;
  cursor: default !important;
  color: rgba(255, 255, 255, 0.35) !important;
}

.btn-secondary-md-blue {
  background-color: #edf5fd !important;
  border: solid 1px #edf5fd !important;
  font-size: 16px !important;
  color: #166ab3 !important;
  height: 40px;
}

.btn-secondary-md-blue:hover {
  border: solid 1px #166ab3 !important;
}

.btn-secondary-md-green {
  background-color: #dcf2f1 !important;
  border: solid 1px #dcf2f1 !important;
  font-size: 16px !important;
  color: #008985 !important;
  height: 40px;
}

.btn-secondary-md-green:hover {
  border: solid 1px #008985 !important;
}

.btn-secondary-md-yellow {
  background-color: #fef1d9 !important;
  border: solid 1px #fef1d9 !important;
  font-size: 16px !important;
  color: #f86b07 !important;
  height: 40px;
}

.btn-secondary-md-yellow:hover {
  border: solid 1px #f86b07 !important;
}

.btn-secondary-md-red {
  background-color: #ffe2e5 !important;
  border: solid 1px #ffe2e5 !important;
  font-size: 16px !important;
  color: #f60038 !important;
  height: 40px;
}

.btn-secondary-md-red:hover {
  border: solid 1px #f60038 !important;
}

.btn-primary-sm-blue {
  background-color: #1e8ced !important;
  font-size: 14px !important;
  height: 32px;
  padding: 4px 16px !important;
  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.btn-primary-sm-blue:hover {
  background-color: #166ab3 !important;
}

.btn-primary-sm-green {
  background-color: #18aca7 !important;
  font-size: 14px !important;
  height: 32px;
  padding: 4px 16px !important;
}

.btn-primary-sm-green:hover {
  background-color: #008985 !important;
}

.btn-primary-sm-yellow {
  background-color: #faa500 !important;
  font-size: 14px !important;
  height: 32px;
  padding: 4px 16px !important;
}

.btn-primary-sm-yellow:hover {
  background-color: #f86b07 !important;
}

.btn-primary-sm-red {
  background-color: #ff4052 !important;
  font-size: 14px !important;
  height: 32px;
  padding: 4px 16px !important;
}

.btn-primary-sm-red:hover {
  background-color: #f60038 !important;
}

.btn-primary-sm-grey {
  background-color: rgba(1, 7, 9, 0.35) !important;
  font-size: 14px !important;
  height: 32px;
  padding: 4px 16px !important;
  cursor: default !important;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.35) !important;
}

.btn-secondary-sm-blue {
  background-color: #edf5fd !important;
  border: solid 1px #edf5fd !important;
  font-size: 14px !important;
  color: #166ab3 !important;
  height: 32px;
  padding: 4px 16px !important;
}

.btn-secondary-sm-blue:hover {
  border: solid 1px #166ab3 !important;
}

.btn-secondary-sm-green {
  background-color: #dcf2f1 !important;
  border: solid 1px #dcf2f1 !important;
  font-size: 14px !important;
  color: #008985 !important;
  height: 32px;
  padding: 4px 16px !important;
}

.btn-secondary-sm-green:hover {
  border: solid 1px #008985 !important;
}

.btn-secondary-sm-yellow {
  background-color: #fef1d9 !important;
  border: solid 1px #fef1d9 !important;
  font-size: 14px !important;
  color: #f86b07 !important;
  height: 32px;
  padding: 4px 16px !important;
}

.btn-secondary-sm-yellow:hover {
  border: solid 1px #f86b07 !important;
}

.btn-secondary-sm-red {
  background-color: #ffe2e5 !important;
  border: solid 1px #ffe2e5 !important;
  font-size: 14px !important;
  color: #f60038 !important;
  height: 32px;
  padding: 4px 16px !important;
}

.btn-secondary-sm-red:hover {
  border: solid 1px #f60038 !important;
}

.rounded-floating-btn {
  color: #166ab3 !important;
  background-color: #fff !important;
  font-weight: bold !important;
  padding: 6px 16px !important;
  border-radius: 20px !important;
  border: solid 1px #1e8ced !important;
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5) !important;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5) !important;
  -ms-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5) !important;
  -o-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5) !important;
  position: fixed !important;
  bottom: 100px !important;
  margin: auto !important;
  right: calc(50% - 64px) !important;
  left: calc(50% - 64px) !important;
  min-width: 140px !important;
}
