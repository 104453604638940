.sort-item {
  font-size: 14px;
}

.margin-top-10 {
  margin-top: 10px;
}

.paddingleft27 {
  padding-left: 27px;
}

.drawer-leave-name:hover {
  text-decoration: underline;
  color: #166ab3;
}

.select-sort div {
  padding: 10.5px 30px;
  padding-left: 8px;
}

.select-active div div {
  color: #166ab3;
}

.select-active svg {
  color: #166ab3;
}

.listhover {
  cursor: pointer;
  height: 40px;
}

.listhover:hover {
  background-color: #edf5fd;
}

.showviewbtn {
  display: none;
  justify-content: space-between;
}

.listhover:hover .showviewbtn {
  /* display: block; */
  display: flex;
}

.viewbtn {
  display: block;
}

.listhover:hover .viewbtn {
  display: none;
}

.pipelineassigneddata-span::after {
  content: ",";
}

/* .pipelineassigneddata .pipelineassigneddata-span::after {
  content: ",";
} */

.pipelineassigneddata-span:last-child::after {
  content: "";
}

.last-conversation p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7);
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
