// @media (max-width: 767px) {
//   .root-display-desktop {
//     display: none;
//   }
//   .root-display-mobile {
//     display: block;
//   }
// }

// @media (min-width: 768px) {
//   .root-display-desktop {
//     display: block;
//   }
//   .root-display-mobile {
//     display: none;
//   }
// }

@media screen and (max-width: 1420px) {
  .lead-signup-form {
    padding: 0px;
    width: 328x;
    background: white;
    border-radius: 5px;
  }
  .signup-up-button-padding {
    padding-top: 5px;
  }
  .roleselect {
    padding-top: 40px;
  }

  .testimonial-padding {
    padding: 0 90px;
    margin-top: 10%;
  }
  .lead-signup-form .padding-top-60 {
    padding-top: 30px;
  }
  .sucess-gif img {
    height: 150px;
  }
  .type-form-wrapper {
    height: 400px;
  }
  .purchase-form-wrapper {
    height: 430px;
  }

  .table-row-height {
    td {
      font-size: 14px;
    }
    height: 30px !important;
  }
  .helptext-img {
    height: 160px;
  }
  .confirm-transfer-btn {
    background: #166ab3;
    color: #fff;
    padding: 15px 16px;
    font-weight: 600;
    border-radius: 4px;
    text-transform: uppercase;
    height: 56px;
    outline: none;
    border: none;
  }
  .phone-input-typeform {
    width: 83% !important;
  }
  .code-generator-label {
    font-size: 10px;
  }
  // .stepper-scroller {
  //   max-height: 560px;
  // }
}

@media screen and (max-width: 1200px) {
  // .stepper-scroller {
  //   max-height: 430px;
  // }
  .lead-signup-form {
    padding: 0px;
    width: 328px;
    background: white;
    border-radius: 5px;
  }
  .leadloginbutton {
    margin-top: 30px !important;
  }

  .dir-emp-links:first-child {
    padding-left: 20px;
  }
  .emp-designation {
    width: auto;
  }

  .dir-emp-details {
    height: auto;
  }
  .code-generator-label {
    font-size: 10px;
  }
  .emp-code-nav-head {
    left: 28px;
    font-size: 10px;
  }
  .emp-code-nav {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .form-group {
    input {
      width: 410px;
    }
    textarea {
      width: 430px !important;
    }
    .selectsearch {
      width: 435px !important;
    }
    select,
    ul,
    table {
      width: 424px;
    }
  }
  .type-form-wrapper {
    width: 480px;
  }
  .subcription-payment-head {
    font-size: 18px;
  }
  .card-heading span {
    padding-left: 10px;
  }

  .code-generator-label {
    margin-left: 2px;
    margin-right: 2px;
    color: #000000;
    font-size: 10px;
  }
  .emp-code-input {
    width: 30px !important;
    height: 30px !important;
  }
  .shift-planner-form {
    input,
    ul,
    select,
    .depedent-select-list {
      width: 70% !important;
    }
    .selectsearch {
      width: 74% !important;
    }
  }
}

@media screen and (max-width: 1025px) {
  .tabheading {
    span {
      letter-spacing: 0;
      padding: 4px;
      font-size: 14px;
    }
    min-width: auto !important;
  }
  .form-group {
    input {
      width: 360px;
    }
    textarea {
      width: 375px !important;
    }
    .selectsearch {
      width: 380px !important;
    }
  }
  .type-form-wrapper {
    width: 430px;
  }
  .type-form-wrapper.purchase-form-wrapper {
    width: 530px !important;
  }

  .win-height,
  .left-navigation {
    height: auto;
  }

  .ql-snow .ql-formats {
    vertical-align: initial;
    float: left;
  }
  .emp-code-input {
    width: 30px !important;
  }
  .ipAddressInput {
    width: 115px !important;
    padding: 10px;
  }
  .vertical-tab-section {
    padding: 0px 0px 0px 10px;
  }
  .social-icon {
    padding: 5px 5px;
  }
  .emp-profile-page-landing {
    /* padding: 20px 0px 60px 80px; */
    display: grid;
    grid-template-columns: 25% 100% !important;
  }
  .form-btn-helptxt {
    font-size: 14px;
  }
  .emp-add-head {
    font-size: 18px;
  }

  .emp-code-generator-section {
    padding: 10px 0 15px 0px;
    margin-top: 10px;
  }
  .profile-landing-emp-img {
    height: 350px;
    padding-top: 50px;
  }
  .profile-pic-section {
    width: 450px !important;
  }
  .basic-profile-container {
    width: 390px !important;
    .react-tel-input input {
      &[type="tel"],
      &[type="text"] {
        width: 99% !important;
      }
    }
  }
  .imp-dates .e-input-group {
    position: relative;
    width: 86% !important;
    &.e-control-wrapper {
      position: relative;
      width: 86% !important;
    }
  }

  .banklogo-div {
    margin-top: -15px;
  }
  .banklogo {
    height: 90px;
  }
  .razorpay-img img {
    height: 40px;
  }

  .code-generator-label {
    font-size: 9px;
  }

  .applogin-bgimg1 {
    height: 100vh !important;
    position: relative;
    top: 0px;
    /* padding-bottom: 90px; */
    margin: 0 auto;
    text-align: center;
    right: 0;
    left: 0;
    color: #fff;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
    -ms-transform: translateY(0%);
  }
  .emp-from-wrapper.imp-dates .form-group {
    .selectsearch {
      width: 365px !important;
      font-size: 16px;
    }
    input {
      width: 340px !important;
    }
  }
  .shift-planner-form {
    input,
    ul,
    select,
    .depedent-select-list {
      width: 70% !important;
    }
    .selectsearch {
      width: 74% !important;
    }
  }
  .permissions-div {
    background: #e3e7f2;
    border-radius: 50%;
    border: 1.3px solid #166ab3;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    text-align: center;
  }
  .middelText {
    margin-left: 6px;
  }
  .permissions-block {
    height: 232px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 10px;
    margin: 0 auto;
  }
  .paddingtopleft {
    padding: 17px;
    background-color: #d1e1fb;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    /* */
    /* */
    /* line-height: 0.49; */
    letter-spacing: 0.9px;
    color: rgba(14, 14, 14, 0.38);
  }
  .permissions-img {
    height: 91px;
    /* width: 100%; */
    margin: 0 auto;
    text-align: center;
    margin-bottom: -15px;
    border-radius: 50%;
  }
  .permissions-subblock {
    border-radius: 2px;
    color: #166ab3;
    height: 15px;
    font-size: 16px;
    font-weight: 410;
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  .backButton {
    position: relative !important;
    bottom: 0px;
    left: -10px;
    color: #159bff !important;
  }
  .tabheading {
    min-width: auto !important;
  }

  .HRMSimplified-div {
    height: auto;
    padding-bottom: 60px;
  }

  .signup-right-side {
    display: none !important;
  }

  .signup-left-side {
    // width: 75vw !important;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .confirm-transfer-btn {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .typeform-heading {
    line-height: 2;
  }

  .applogin-cont {
    position: absolute;
    top: 50%;
    padding: 0;
    text-align: center;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
}

@media (min-width: 959px) {
  .tabheading {
    min-width: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .sign-up-div-show {
    display: none;
  }
  .root-display-desktop {
    display: block;
  }
  .desktop-div {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .sign-up-div-show {
    display: block;
  }
  // .root-display-desktop {
  //   display: none;
  // }
  .desktop-div {
    display: none;
  }
  .already-account-div {
    display: none;
  }

  #faq-bot-button,
  .BeaconFabButtonFrame {
    display: none !important;
  }
}

@media screen and (max-width: 769px) {
  .manager-section {
    margin-left: 0px;
    margin-top: 15px;
  }
  .lead-signup-form {
    padding: 0px;
    width: 50%;
    background: white;
    border-radius: 5px;
  }

  .HRMSimplified-div {
    height: 100% !important;
    padding-bottom: 40px;
  }
  .dashboard-card {
    margin-top: 30px;
  }
  .transition-desc {
    width: 99%;
    height: 150px;
    font-size: 16.3px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.72;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.38);
  }
  .type-form-wrapper {
    overflow: visible;
  }
  .basic-profile-container {
    overflow: hidden !important;
  }
  .dashboard-tab-width {
    width: 98% !important;
  }
  .sucessGif img {
    height: 120px;
  }
  .ql-toolbar.ql-snow {
    overflow: hidden;
  }
  .ql-snow .ql-formats {
    display: block !important;
    float: left !important;
  }
  .emp-designation {
    text-align: center;
    padding: 0;
  }
  .main-emp-profile-section {
    padding-top: 30px;
    grid-column-start: 2;
  }
  .emp-section-innerwrapper {
    padding-left: 0px;
  }
  .emp-profile-page-landing .e-input-group {
    width: 96% !important;
    &.e-control-wrapper {
      width: 96% !important;
    }
  }
  .emp-code-vertical-divider {
    height: 20px;
  }
  .imp-dates {
    .e-input-group {
      position: relative;
      width: 86% !important;
      &.e-control-wrapper {
        position: relative;
        width: 86% !important;
      }
    }
    width: 320px !important;
  }
  // .dir-emp-img {
  //   padding-top: 18px;
  // }
  .dir-emp-details p {
    font-size: 12px;
  }
  .dependent-datepicker {
    width: 99% !important;
  }
  .manage-account-field {
    .react-tel-input {
      width: 96%;
    }
    .e-input-group {
      width: 90% !important;
      &.e-control-wrapper {
        width: 90% !important;
      }
    }
  }
  .manageleadpage .tableCell {
    font-size: 10px;
  }
  .cycle-desc {
    width: 99%;
    height: 252px;
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46429em;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.38);
    span {
      font-size: 0.875rem;
      line-height: 1.46429em;
    }
  }
  .cycleList .MuiMenuItem-root-22 {
    width: 100% !important;
  }
  .shift-planner-form {
    input,
    ul,
    select,
    .depedent-select-list {
      width: 70% !important;
    }
    .selectsearch {
      width: 74% !important;
    }
  }
  .permissions-div {
    background: #e3e7f2;
    border-radius: 50%;
    border: 1.3px solid #166ab3;
    padding-top: 10px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 0px;
    text-align: center;
  }
  .middelText {
    margin-left: 4px;
  }
}

@media screen and (max-width: 737px) {
  .pagenotfound {
    height: 250px;
  }
}

@media screen and (max-width: 568px) {
  .HRMSimplified,
  .hello-welcome {
    font-size: 40px;
  }
  .simplifided-slogan {
    font-size: 20px;
  }
  .testimonial-padding {
    padding: 0 20px 0 40px;
  }
}

@media screen and (max-width: 415px) {
  .HRMSimplified {
    font-size: 34px;
  }
  .hello-welcome {
    font-size: 34px;
    padding-top: 30px;
  }
  .leadloginbutton {
    width: 165px;
    height: 50px;
    span {
      font-size: 12px;
    }
  }
  .sucessGif img {
    height: 230px;
  }
  .pagenotfoundmessage {
    padding-top: 50%;
  }
}

@media screen and (max-width: 376px) {
  .HRMSimplified,
  .hello-welcome {
    font-size: 30px;
  }

  .leadloginbutton {
    width: 200px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .empDesName,
  .empTeam,
  .empJoiningDate,
  .empBirthDate {
    width: 45% !important;
    margin-top: 15px;
  }
  .dir-emp-info {
    width: 300px;
  }
  .dir-emp-details-left {
    flex-basis: 0 !important;
  }
  .dir-emp-details-right {
    margin-top: 60px !important;
    padding-left: 6px;
    max-width: 85% !important;
    flex-basis: 85% !important;
  }
  .dir-emp-links {
    width: 100% !important;
    height: 30px !important;
  }
  .dir-emp-details-main {
    height: auto !important;
  }
  .emp-mob-email-height {
    margin-top: 20px !important;
    height: 68px;
  }
  .emp-mob svg,
  .emp-mail svg {
    top: 3px !important;
  }
  .dir-emp-links {
    &:first-child {
      padding-left: 0 !important;
    }
    display: block !important;
    margin-top: 10px !important;
  }
  .dir-emp-details-right1 {
    max-width: 60% !important;
  }
  .emp-mob-email-height-outer {
    max-width: 40% !important;
  }
  .emp-mail-icon {
    margin-top: 0px !important;
  }
  .emp-mail-text {
    margin-left: -5px !important;
  }
  .emp-mail-icon:hover {
    background: transparent !important;
  }
}

@media (max-width: 900px) {
  .dir-emp-links {
    width: 50% !important;
    display: inline-block !important;
  }
  .emp-mob-email-height-outer,
  .dir-emp-details-right1 {
    display: block;
    max-width: 100% !important;
  }
  .dir-emp-links:first-child {
    padding-left: 0px !important;
  }
  .emp-mob-email-height {
    height: 40px !important;
    margin-top: 0px !important;
  }
  .dir-emp-details-right {
    margin-top: -15px !important;
    margin-left: 94px !important;
    display: block !important;
  }
  .emp-mail-icon {
    margin-left: 0 !important;
  }
  .emp-mail .emp-mail-icon {
    margin-left: (-10px) !important;
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  .container-left-pane {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .signup-right-side {
    max-width: 50% !important;
    flex-basis: 50% !important;
    // position: absolute;
  }
}

@media (min-width: 768px) and (max-width: 975px) {
  .emp-mail {
    margin-left: -18px !important;
  }
  .content-subwrapper,
  .nav-top-fixed1 {
    position: relative;
    top: 0px;
    width: 100%;
    max-width: 100% !important;
  }
  .outer-div {
    width: 100% !important;
    margin-left: 0;
    margin-top: 30px;
  }
  .manager-section {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .dir-emp-details-right {
    display: none !important;
  }
}

@media (min-width: 960px) {
  .MuiSnackbarContent-root-999,
  .MuiSnackbarContent-root-450 {
    max-width: 700px !important;
  }
}

@media (min-width: 976px) {
  .content-subwrapper {
    display: flex;
    justify-content: space-between;
  }
  .content-subwrapper2 {
    display: block;
  }
}

@media (min-width: 1201px) {
  .emp-mail-text {
    display: none !important;
  }
}

@media (max-width: 1440px) {
  .nav-top-fixed {
    position: fixed !important;
  }
}

@media (max-width: 1024px) {
  .nav-top-fixed {
    position: fixed !important;
  }
}

@media (max-width: 600px) {
  .nav-top-fixed {
    width: 60%;
    position: inherit !important;
  }
}
