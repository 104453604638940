/******* Request Counter ********/

.req-message-head {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: rgba(16, 16, 16, 0.87);
}

.request-mainhead {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}

.req-message-head-rightDrawer {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: rgba(16, 16, 16, 0.87);
  font-weight: bold;
}

.req-message-cont {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  position: relative;
  padding-top: 4px;
}

.request-cenetr-newemp .req-message-cont {
  font-size: 12px !important;
}

.message-edit {
  top: 0;
  right: 0;
  border: 1px solid #166ab3;
  border-radius: 50px;
  padding: 0px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  fill: #166ab3;
  &:hover {
    background: #166ab3;
    path {
      fill: #fff;
    }
  }
}

.request-empprofile {
  height: 72px;
  width: 72px;
  border-radius: 50px;
}

.planner-empprofile {
  height: 58px;
  width: 58px;
  border-radius: 50px;
}

.request-empprofile-info {
  float: left;
  margin-left: 10px;
  width: 70%;
  min-height: 84px;
}

.req-message-time {
  color: rgba(16, 16, 16, 0.6);
  text-transform: capitalize;
  font-size: 12px;
  padding-right: 5px;
  padding-top: 10px;
}

#div1 {
  overflow-x: hidden;
}

.requestcenter-form {
  width: 94%;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
  .react-tel-input input {
    &[type="tel"],
    &[type="text"] {
      height: 38px !important;
    }
  }
  .e-date-wrapper {
    height: 28px !important;
  }
  .css-vj8t7z {
    background: transparent !important;
    min-height: 40px !important;
  }
}

.request-search-icon {
  position: absolute;
  padding: 10px;
  color: #fff;
}

::placeholder {
  color: #fff;
}

.request-filter-icon {
  position: absolute;
  right: 5px;
  top: 7px;
  cursor: pointer;
  svg {
    fill: #00000073;
    padding: 5px;
    height: 26px;
    width: 42px;
  }
}

.request-filter-icon2 {
  position: absolute;
  right: 40px;
  top: 6px;
  cursor: pointer;
  svg {
    fill: #00000073;
    padding: 2px 5px;
    height: 28px;
    width: 35px;
    border-left: 2px solid #00000073;
  }
}

.request-password .react-password-generator__input {
  padding: 10px !important;
  width: 71% !important;
  background: transparent;
  margin-right: 8px;
}

.requestcenter-email {
  padding: 10px !important;
  width: 100% !important;
  background: transparent;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cccccc;
}

.requestcenter-form {
  .e-date-wrapper {
    height: 40px !important;
  }
  .inner-wrapper {
    padding-top: 10px;
  }
}

.aprove-reject-button {
  /* position: absolute; */
  padding-top: 16px;
  /* margin-left: 80px; */
}

.requestcenter-bankdetails {
  padding-top: 10px;
}

.request-center-drawer {
  background: #fbfbfb;
  width: 100%;
  padding: 30px;
  min-height: 100vh;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}
.request-cenetr-newemp {
  overflow-y: scroll;
  padding: 5px 5px 0 2px;
  height: 70vh;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}
