/*********** Device Policy *************/

.select {
  width: 100%;
}

.device-form-img {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}

.iplist-attendence ul li {
  height: 72px;
  background: #f5f5f5;
}

.inner-level-div {
  margin: 0px 16px;
  background: #eee;
}

.first-level-padding {
  padding: 8px 32px;
}

.inner-level-div-second-level {
  background: #f5f5f5;
  margin: 0px 16px;
}

.Approval .e-popup-wrapper {
  border: none;
}
