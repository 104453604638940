.bottom-border {
  border-bottom: 2px solid #d8d8d8 !important;
}

.right-border {
  border-right: 0px solid #d8d8d8 !important;
}

.top-border {
  border-top: 2px solid #d8d8d8 !important;
}

.bottom-border-thin {
  border-bottom: 1px solid #d8d8d8 !important;
}

.block-selected {
  border: solid 1px $DodgerBlue !important;
}

.block-unselected {
  border: solid 1px #d9dadb !important;
}

.dashed-border {
  border-bottom: 1px dashed #979797;
}

.dashed-divider {
  margin: 0 16px;
  height: 0px;
  border-bottom: 1px dashed #d9dadb;
}

.dashed-divider1 {
  margin: 16px 0px;
  height: 0px;
  border-bottom: 1px dashed #d9dadb;
}
