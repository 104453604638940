.drawer-header {
  margin: 6px 0px 6px 10px;
  width: 84%;
  text-align: center;
  color: #010709;
  font-size: 19px;
  font-weight: 700;
}

.drawer-close-btn {
  background: transparent;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 6px;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  svg {
    fill: rgba(0, 0, 0, 0.54);
  }
  &:hover svg {
    fill: rgba(0, 0, 0, 1);
  }
  img {
    position: relative;
    top: 4px;
    left: 4px;
  }
}

.drawer-container {
  padding: 16px;
  margin: 24px 0px;
}

.drawer-input-group {
  margin-bottom: 10px;
}

.delete-btn {
  background: #ffe2e5 !important;
  color: #f60038 !important;
  box-shadow: none !important;
  margin-right: 16px !important;
}

.primary-btn {
  box-shadow: none !important;
}

.disabled-btn {
  box-shadow: none !important;
  background: rgba(1, 7, 9, 0.35);
}

.action-btn-group {
  margin-top: 24px;
}

.remove-attachment-icon {
  position: absolute;
  right: -8px;
  float: right;
  top: -8px;
  z-index: 1;
}

.drawer-main {
  width: 30%;
  overflow-x: hidden;
  scrollbar-width: none;
  scrollbar-color: #555 transparent;
}

.drawer-main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  background-color: #555;
}

.drawer-main::-webkit-scrollbar {
  width: 0px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.Drawer-header-wrapper {
  // height: 36px;
  margin-bottom: 16px;
  display: flex;
}
