/**************** Employee Profile ********/

.emp-add-head {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.emp-add-desc {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.employe-status-dection {
  background-image: radial-gradient(circle at 100% 100%, #2ecaff, #166ab3);
  padding: 10px;
  margin-bottom: 30px;
}

.employ-processbar-status {
  font-size: 48px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
}

.employ-processbar-desc {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.27;
  letter-spacing: 0.2px;
  color: #000000;
}

.emp-status-btn {
  width: 140px;
  height: 55px;
  background-color: #166ab3;
}

.emp-process-bar {
  width: 110px;
}

.graybg {
  background: #00000040;
}

.disabled-cell {
  background: #f6f7f8 !important;
}

.profile-img {
  width: 100%;
  height: 100%;
}

.add-emp-profile-img {
  width: 100%;
  height: 100%;
}

.profile-img input {
  position: absolute;
  top: 11px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 40px;
  width: 40px;
  opacity: 0;
  cursor: pointer;
}

.profile-img img {
  height: 69%;
  width: 69%;
  border-radius: 50%;
}

.onhoverbtn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px solid #166ab3;
  background: #166ab3;
  border-radius: 50px;
  padding: 6px;
  height: 20px !important;
  fill: #fff;
  width: 20px !important;
  opacity: 0;
  cursor: pointer !important;
}

.onhoverbtn svg {
  cursor: pointer;
}

.profile-img:hover .onhoverbtn {
  opacity: 1;
}
.profile-img:hover .onhoverbtn-basicprofile {
  opacity: 1;
}

.add-emp-profile-img:hover .onhoverbtn-basicprofile {
  opacity: 1;
}

.emp-name-1 {
  font-size: 20px;
  font-weight: 500;
  padding: 0px 20px;
  border: none;
}

.name-required-width {
  width: 135px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.social-icon {
  padding: 10px 10px;
}

.emp-section-innerwrapper {
  position: relative;
  padding-top: 15px;
}

.var-tab-heading {
  span {
    text-align: left !important;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-transform: capitalize;
    letter-spacing: 0px;
    flex-direction: row;
  }
  border-bottom: none !important;
  text-align: left !important;
  min-height: 48px !important;
  padding-left: 16px !important;
  padding-top: 0px !important;
  &:focus {
    background: whitesmoke;
  }
}

.ver-tab-padding {
  [aria-selected="true"] {
    color: #166ab3;
    border-right: 4px solid;
  }
  [aria-selected="false"] {
    border-right: 4px solid #d3d3d3;
  }
}

.rating-icons {
  text-align: center;
  font-size: 19px;
  padding: 11px 0px 8px 0px;
  background: #f2f2f2;
  margin-top: 0px;
  border-radius: 4px;
}

.add-lefe-input,
.new add-lefe-input {
  height: 40px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.32);
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.53);
  width: 50%;
  padding: 10px;
  background: #fff;
}

.image-upload.branding-upload-img {
  position: absolute;
}

.otprefresh {
  position: absolute;
  right: 8px;
  top: 10px;
}

.emp-from-wrapper .type-form-wrapper {
  height: 320px !important;
  padding-top: 0px !important;
}

.address-edit {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #166ab3;
  border-radius: 50px;
  padding: 5px;
  height: 18px !important;
  fill: #166ab3;
  width: 18px !important;
}

.superuser-edit-icon {
  position: absolute;
  top: 2px;
  right: 0;
  border: 1px solid #166ab3;
  border-radius: 50px;
  padding: 5px;
  height: 20px !important;
  fill: #166ab3;
  width: 20px !important;
}

.privacy-block .address-edit {
  top: 3px;
}

.address-edit:hover {
  fill: #fff !important;
  background: #166ab3;
}

.inputbox {
  height: 38px;
  padding: 0 0 0 10px;
}

.team-avatar {
  /* margin-left: -30px; */
  border-radius: 50px;
}

.manager-section {
  margin-left: 15px;
}

.team-member-profile a {
  .inlineClass {
    margin-left: -30px;
  }
  &:first-child .inlineClass {
    margin-left: 0px;
  }
}

.MuiGrid-grid-md-9-149 {
  flex-basis: 70% !important;
}

.team-img1 {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  margin-left: 5px;
}

.team-img {
  height: 55px;
  width: 55px;
  border-radius: 50px;
  margin-left: 5px;
}

.team-height {
  min-height: 100px;
}

.inner-tab-bg {
  position: absolute;
  text-align: right;
  right: 30px;
}

.e-input {
  &:not(:valid),
  &:valid {
    border-bottom: none !important;
  }
}

.e-float-input {
  &.e-control-wrapper input {
    &:not(:valid),
    &:valid {
      border-bottom: none !important;
    }
  }
  input {
    &:not(:valid),
    &:valid {
      border-bottom: none !important;
    }
  }
}

.e-input-group {
  input {
    &:not(:valid),
    &:valid {
      border-bottom: none !important;
    }
  }
  &.e-control-wrapper input {
    &:not(:valid),
    &:valid {
      border-bottom: none !important;
    }
  }
}

.e-float-input {
  &.e-control-wrapper textarea {
    &:not(:valid),
    &:valid {
      border-bottom: none !important;
    }
  }
  textarea {
    &:not(:valid),
    &:valid {
      border-bottom: none !important;
    }
  }
}

.e-input-group {
  &.e-control-wrapper textarea {
    &:not(:valid),
    &:valid {
      border-bottom: none !important;
    }
  }
  textarea {
    &:not(:valid),
    &:valid {
      border-bottom: none !important;
    }
  }
  &:not(.e-float-icon-left),
  &.e-control-wrapper:not(.e-float-icon-left) {
    border-bottom: none !important;
    border: 1px solid #00000040 !important;
    padding: 5px;
  }
}

.e-input-focus {
  border-bottom: none !important;
}

.e-input-group {
  &.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::before,
  &:not(.e-float-icon-left):not(.e-float-input)::before,
  &.e-control-wrapper:not(.e-disabled):not(.e-float-icon-left)::after,
  &:not(.e-float-icon-left):not(.e-float-input)::after {
    background: transparent !important;
    width: 0px !important;
  }
}

.sidebarsection-height {
  max-height: 300px;
  overflow-y: auto;
}
