/**************** Config Global **********/

.typeform-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.38);
  text-decoration: none;
  outline: none;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    color: #166ab3;
  }
  &.active-color {
    color: #166ab3 !important;
  }
}

.typeform-next-button {
  padding-top: 30px;
  padding-bottom: 30px;
}

.type-form-wrapper {
  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.38);
  }
  position: relative;
  height: 600px;
  width: 600px;
  padding-top: 40px;
  clear: both;
  overflow: hidden;
}

.purchase-form-wrapper {
  width: 600px;
  margin: 0 auto;
}

.field {
  margin-bottom: 30px;
  transition: 0.6s ease all;
}

.form-input-field input {
  width: 100%;
  border-top: none;
  height: 56px;
  border-left: none;
  border-right: none;
  opacity: 1;
  &:focus,
  &:hover,
  &:visited {
    outline: none;
    text-decoration: none;
  }
}

.field {
  label {
    display: block;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
  }
  input,
  select {
    width: 100%;
    height: 56px;
    border-bottom: 2px solid #979797;
    border-top: none;
    border-right: none;
    border-left: none;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
  }
  input {
    &:focus,
    &:visited,
    &:active {
      border-bottom: 2px solid #166ab3;
      text-decoration: none;
      outline: none;
      outline-offset: 0px;
    }
  }
  select {
    &:focus,
    &:visited,
    &:active {
      border-bottom: 2px solid #166ab3;
      text-decoration: none;
      outline: none;
      outline-offset: 0px;
    }
  }
  &.active,
  &:hover,
  .button {
    opacity: 1;
  }
}

.type-form-wrapper {
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.roleselect ul li:last-child,
.field ul li:last-child {
  border-bottom: none !important;
}

.custom-form-box {
  height: 3000px;
}

.form-btn {
  margin-top: 15px;
  width: 100px;
  color: #fff;
  background-color: #166ab3;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  border: 0px !important;
  box-shadow: 0 0 0 !important;
}

.form-btnsuper {
  margin-top: 15px;
  width: 60%;
  color: #fff;
  background-color: #166ab3;
  padding: 10px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0 0 0 !important;
}

.form-btnsupersmall {
  margin-top: 15px;
  width: 40%;
  color: #fff;
  background-color: #166ab3;
  padding: 10px;
  text-align: center;
  border-radius: 6px;
  margin-left: 12%;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0 0 0 !important;
}

#div {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

.customdiv {
  position: absolute;
  width: 20px;
  height: 100vh;
  right: 0px;
  background: #fff;
}

#div {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

#div2 {
  margin-top: 100px;
}

.opaque {
  opacity: 1;
}

.inner-wrapper {
  outline: none;
}

.form-group {
  select,
  table,
  select,
  table {
    width: 500px;
    padding: 10px;
    font-size: 16px;
  }
  select {
    &:hover,
    &:focus {
      border: 2px solid #166ab3;
    }
  }
}

.form-group ul {
  width: 490px;
  padding: 10px 0 0 0;
  font-size: 16px;
}

.selectlist-component li {
  &:first-child {
    border-top: 2px solid #9797976b !important;
  }
  white-space: pre-line;
  padding-left: 16px !important;
}

.form-group {
  input {
    width: 477px;
    padding: 10px;
    font-size: 16px;
  }

  input:focus {
    border: 1px solid #166ab3;
    outline: 0;
    border-radius: 4px;
  }
}

textarea:focus {
  border: 1px solid #166ab3;
  outline: 0;
}

.css-2o5izw {
  border-left: 2px solid #166ab3 !important;
}

.form-group {
  .quill {
    width: 505px;
  }
  textarea {
    width: 500px !important;
    font-size: 16px;
    height: 100px;
    padding: 6px 0px 0px 10px;
    border-radius: 4px;
  }
}

.type-form-wrapper textarea {
  width: 60% !important;
}

.form-group {
  .selectsearch {
    /* width: 500px !important; */
    width: 100% !important;
    font-size: 16px;
  }
  outline: none;
  padding-bottom: 20px;
  position: relative;
  /* padding: 2px; */
  padding-bottom: 20px;
}

.form-btn-helptxt,
.form-btn-helptxt-menulist {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
  font-weight: 500;
  letter-spacing: 0.5px;
  padding-left: 15px;
}

.input-form-label {
  padding: 4px 0;
  margin: 0;
  font-size: 14px;
  color: #166ab3;
}

.active-color,
.active-color,
.typeform-heading.active-color {
  color: #035ec1 !important;
}

.active-next-prev-color {
  color: rgba(0, 0, 0, 0.87);
}

.planner-dective-color {
  color: #7ed321;
}

.ShowPassword {
  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.visibilitynone {
  opacity: 0;
  position: absolute;
  top: 0;
}

.margintop-50 {
  margin-top: -50px !important;
}

.table-row-height {
  height: 40px !important;
  td {
    border: none !important;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    padding: 0;
  }
}

.razorpay-img img {
  height: 60px;
}

.banklogo-div {
  margin-top: -30px;
}

.banklogo {
  height: 120px;
}

.bank-details-section {
  width: 500px;
  margin: 0 auto;
  text-align: left;
}

.quickhelp-section {
  background: #fafafa;
  height: 100vh;
  padding: 0px 80px 0px 24px;
}

.helptext-img {
  height: 260px;
}

.pagenotfound {
  position: fixed;
  right: 0px;
  bottom: 0;
  margin: 80px;
  height: 500px;
}

.pagenotfoundmessage {
  width: 300px;
  padding-top: 24%;
  margin-left: 8%;
  transform: translateY(-50%);
  h3 {
    padding: 0;
    margin: 0;
  }
}

.sucess-wrapper {
  width: 600px;
  margin: 0 auto;
}

.confirm-transfer-btn {
  height: 56px;
  width: 100%;
}

.filter-icon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #edf5fd;
  position: relative;
  cursor: pointer;
}

.vertical-center {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.element-selected {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  text-align: center;
}

.message-text {
  background: #edf5fd;
  margin: 16px 0px;
  height: 46px;
  padding: 8px;
  display: table;
  width: 100%;
}
