/************** Employee Code *********/

.emp-code-nav {
  height: 20px;
  width: 20px;
  line-height: 24px;
  border: 1px solid;
  border-radius: 50px;
  text-align: center;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.emp-code-nav-head {
  position: absolute;
  left: 30px;
  top: -8px;
}

.emp-code-form-wrapper {
  width: 100% !important;
  height: auto !important;
  padding-top: 0px;
  #div {
    height: 100vh !important;
  }
}

.emp-code-vertical-divider {
  height: 49px;
  border-left: 1px solid;
  width: 2px;
  margin-left: 11px;
}

.emp-code-generator-section {
  padding: 15px 0 15px 0px;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 2px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  height: 164px;
}

.emp-code-input {
  height: 30px !important;
  width: 40px !important;
  padding: 0px 0px 0px 5px !important;
  margin: 0 !important;
}

.uppercase-switch {
  position: absolute;
  right: 0;
  top: 5px;
  input {
    width: 40px !important;
  }
}
.emp-code-radio-button {
  padding: 5px !important;
}

.code-generator-label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 5px;
  margin-right: 5px;
}

.emp-code-radio-button input {
  height: 30px !important;
  width: 30px !important;
}

.choose-section {
  border-bottom: 1px solid #00000042;
  padding: 20px 0 20px 0;
  width: 600px;
}

.manually-upload-section {
  display: inline-block;
  text-align: center;
  margin-right: 15px;
}

.filter-datepicker .e-date-range-wrapper {
  width: 94%;
  border-radius: 4px;
  border-color: #00000040 !important;
  height: 38px;
}

.emp-radiobutton-width {
  padding: 5px 0px 5px 10px !important;
  input {
    width: 30px !important;
  }
}

.custom-prefix-divider .inlineClass {
  padding-top: 3px;
  padding-bottom: 3px;
}

.emp-code-preview {
  width: 95%;
  padding: 10px;
  border: 1px solid #0000004f;
}

.emp-code-preview-label {
  color: #166ab3;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
}
