.assign-planner-head {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  margin-top: 12px;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.87);
}

.designitation-assign-designation-section {
  width: 100%;
  position: relative;
}

.monthselect {
  margin: 0;
  padding: 0px;
  display: -webkit-box;
  width: 100%;
}

.monthselect li {
  width: 136px;
  text-align: center;
  list-style: none;
  padding: 6px;
  height: 68px;
  border-left: 1px solid #edf5fd;
  border-bottom: 1px solid #edf5fd;
  position: relative;
  cursor: pointer;
}

.policyPlanner li {
  width: 100% !important;
  text-align: center;
  list-style: none;
  padding: 10px 0 10px 0;
  height: 80px;
  background-color: #f6f5f5;
  border-left: 1px solid #edf5fd;
  border-bottom: 1px solid #edf5fd;
  position: relative;
}

.selected-option {
  // background-color: #fff;
  &.activedflag {
    &.selected-newdesignation {
      background-color: #1082ff !important;
      color: #ffffff !important;
    }
    &.selected-changedesignation {
      background-color: #7ed321 !important;
      color: #ffffff !important;
    }
  }
}

.selected-newdesignation:hover {
  background-color: #1082ff !important;
  color: #ffffff !important;
}

.selected-changedesignation:hover {
  background-color: #7ed321 !important;
  color: #ffffff !important;
}

.selected-option.activedflag {
  &.selected-changedesignation {
    .changedesignation-month,
    .changedesignation-date {
      color: #fff;
    }
  }
  &.selected-newdesignation {
    .newdesignation-month {
      color: #fff;
    }
    .newdesignation-date {
      color: #fff;
      svg {
        fill: #fff;
      }
    }
  }
  &.selected-changedesignation .changedesignation-date svg {
    fill: #fff;
  }
  background: #fff;
}

.selected-newdesignation:hover {
  .newdesignation-date,
  .newdesignation-month {
    color: #fff;
  }
}

.selected-changedesignation:hover {
  .changedesignation-date,
  .changedesignation-month,
  .changedesignation-designationname {
    color: #fff;
  }
}

.selected-newdesignation:hover {
  .changedesignation-designationname {
    color: #fff;
  }
  .newdesignation-date svg {
    fill: #fff;
  }
}

.selected-changedesignation:hover .changedesignation-date svg {
  fill: #fff;
}

.newdesignation-date {
  color: #1082ff;
  font-weight: 500;
  font-size: 20px;
  svg {
    fill: #1082ff;
  }
}

.changedesignation-date {
  svg {
    fill: #7ed321;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms--transform: scaleX(-1);
    -o--transform: scaleX(-1);
  }
  color: #7ed321;
  font-weight: 500;
  font-size: 20px;
}

.newdesignation-month {
  color: #1082ff;
  font-weight: normal;
  font-size: 12px;
}

.changedesignation-month {
  color: #7ed321;
  font-weight: normal;
  font-size: 12px;
}

.changedesignation-designationname {
  color: #000000;
  font-weight: 500;
  font-size: 10px;
}

.headerData {
  margin: 0;
  padding: 0px;
  display: -webkit-box;
  width: 100%;
  background: #fff;
  height: 52px;
}

.headershiftData {
  margin: 0;
  padding: 0px;
  display: -webkit-box;
  width: 100%;
  background: #fff;
  margin-right: 142px;
  height: 52px;
}

.headerData li {
  border-left: none;
  width: 128px;
  background: #166ab3;
  padding: 15px 10px 14px 10px;
  text-align: center;
  list-style: none;
  border-left: 1px solid #edf5fd;
  color: #fff;
}

.filter-text {
  z-index: 100;
  float: left;
  margin-right: 16px;
  cursor: pointer;
}

.empsearch {
  background: white;
}

.headershiftData li {
  border-left: none;
  width: 10.717%;
  background: #166ab3;
  padding: 6px 0px;
  text-align: center;
  list-style: none;
  border-left: 1px solid #edf5fd;
  color: #fff;
}

.shiftmonthselect {
  margin: 0;
  padding: 0px 0;
  display: -webkit-box;
  width: 100%;
  li {
    width: 14.28%;
    height: 80px !important;
    text-align: center;
    list-style: none;
    border-left: 1px solid #edf5fd;
    border-bottom: 1px solid #edf5fd;
    padding: 0px 0 0px 0;
    margin: 0;
  }
}

.plannerdate-picker {
  width: 100%;
  margin-top: 10px;
  z-index: 1;
  position: relative;
  input {
    height: 20px !important;
    border-radius: 4px !important;
  }
}

.e-input-group {
  border-radius: 4px !important;
}

.planner-input {
  width: 88%;
  margin-left: 15px;
}

.plannersalect-list {
  margin-top: 16px;
}

.planner-datepicker {
  margin-left: 15px;
  width: 88%;
  position: relative;
  z-index: 0;
}

.designitation-emp-section {
  height: 80px;
  width: 100%;
}

.designation-planner-assignsection {
  z-index: 2;
  width: 100%;
  position: sticky !important;
  // padding: 6px 0px 7px 0px;
  background: #166ab3;
  height: 52px;
}

.probation-emp-section .planner-emp-details {
  padding: 0px 5px;
  height: 80px;
}

.desi-planner-emp-details {
  background: #fff;
  border-bottom: 1px solid #edf5fd;
  height: 80px;
  .width60 {
    position: absolute;
  }
}

.planner-emp-details:hover,
.desi-planner-emp-details:hover,
.monthselect-action .lightbg:hover {
  background-color: #fff;
}

.planner-update-cancel-section .message-edit {
  border: 1px solid #166ab3;
}

.scroll-bottom-arrow-planner {
  position: fixed;
  background: #166ab3;
  // background: red;
  cursor: pointer;
  width: 30px;
  margin: auto;
  padding-left: 6px;
  padding-top: 8px;
  left: 93px;
  right: 0;
  z-index: 1000;
  bottom: 20px;
  //display: block;
  //opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: opacity 0.8s ease-in-out;
}

#id-bottom {
  display: block;
  opacity: 1;
}

#id-top {
  display: none;
  opacity: 0;
}
