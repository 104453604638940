/************* Directory *************/

.icon-se {
  position: absolute;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 24px !important;
}

.subscriptionlogin-page {
  overflow: hidden;
}

.directory-page {
  width: 100%;
}

.e-daterangepicker {
  z-index: 1401 !important;
}

.directory-page .quill {
  width: 100% !important;
}

.ql-container.ql-snow {
  height: 100px;
}

.emp-mob-email-height {
  height: 40px;
}

.directory-page .message-sent-but {
  right: 0% !important;
}

.dir-profile-img {
  height: 72px;
  width: 72px;
  border-radius: 50px;
}

.mobil-svg-hover {
  transition: 0.2s ease all;
  cursor: pointer;
}

.mobil-svg-hover-show {
  background: #edf5fd;
  border-radius: 8px;
  font-size: 10px;
  width: 0px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: inline-block;
  visibility: hidden;
  text-align: center;
  transition: 0.2s ease all;
  cursor: pointer;
  height: 20px;
  line-height: 26px;
}

.mobil-svg-hover:hover ~ .mobil-svg-hover-show {
  transition: 0.2s ease all;
  font-size: 10px;
  width: 100px;
  visibility: visible;
}

.dir-emp-details:hover .appricate-count {
  background: #166ab3;
  transition: 0.4s ease all;
  color: #fff;
}

// .dir-emp-links {
//   display: inline-block;
//   width: 30px;
// }

.dir-emp-links {
  width: 50%;
  display: inline-block;
  height: 80px;
  margin-right: 0px;
  overflow: hidden;
  margin-left: 0px;
  margin-top: 0px;
  background: transparent;
}

.dir-emp-details:hover .mobil-svg-hover-show,
.dir-emp-links .mobil-svg-hover-show {
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  font-size: 12px;
  padding-bottom: 5px;
  visibility: visible;
}

.message-sent-but {
  position: absolute;
  right: 0;
  padding: 11px 30px 11px 30px;
  background: #166ab3;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
}

.ql-editor {
  height: 100px;
}

// .dir-emp-img {
//   padding-top: 10px;
//   padding-left: 20px;
//   padding-right: 20px;
// }

.dir-emp-info {
  position: absolute;
  top: 0;
  padding-left: 110px;
  padding-top: 5px;
}

.emp-designation {
  width: 200px;
  text-align: left;
}

.dir-emp-details {
  /* height: 80px; */
  padding: 5px 0 5px 0px;
}

.e-datepicker {
  font-size: 16px !important;
  padding: 0px !important;
}

.filter-save-button {
  cursor: pointer;
  padding: 10px;
  text-align: center;
}
