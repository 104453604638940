@import "./variable.scss";
h1 {
  font-size: 28px;
  font-stretch: normal;
  letter-spacing: normal;
  color: #010709;
}

h4 {
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.87);
}

h5 {
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
}

h6 {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.87);
}

.section-title {
  font-size: 32px;
  line-height: 38px;
  color: rgb(1, 7, 9);
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 0;
}

p {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0px;
}

.subtitle-1 {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.48;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.87);
  span {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.48;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.87);
    word-break: break-word;
  }
}

.main-h2 {
  font-size: 25px;
  font-weight: 700;
  line-height: 31px;
  color: #010709;
}

.headline-h1 {
  font-size: 28px;
  color: rgb(1, 7, 9);
  line-height: 42px;
}

.headline-h2 {
  font-size: 25px;
  color: rgb(1, 7, 9);
  line-height: 39px;
}

.headline-h3 {
  font-size: 22px;
  color: rgb(1, 7, 9);
  line-height: 33px;
  font-weight: bold;
}

.table-header {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #010709;
  font-weight: 700;
}

.large-text-dark {
  font-size: 19px;
  font-weight: bold;
  color: #010709;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.large-text-dark-secondary {
  font-size: 19px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.large-text-dark-disabled {
  font-size: 19px;
  color: rgba(1, 7, 9, 0.35);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.large-text-dark-blue {
  font-size: 19px;
  color: #1e8ced;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.normal-dark {
  font-size: 16px;
  color: #010709;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.normal-dark-secondary {
  font-size: 16px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.normal-dark-disabled {
  font-size: 16px;
  color: rgba(1, 7, 9, 0.35);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.normal-blue-dark {
  font-size: 16px;
  color: #035ec1;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.normal-blue {
  font-size: 16px;
  color: #1e8ced;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.small-dark {
  font-size: 14px;
  color: #010709;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.small-dark-secondary {
  font-size: 14px !important;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.small-dark-disabled {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.35);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.small-blue {
  font-size: 14px !important;
  color: #1e8ced !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.small-blue-dark {
  font-size: 14px !important;
  color: #166ab3 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.extra-small-dark {
  font-size: 12px;
  color: #010709;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.extra-small-dark-secondary {
  font-size: 12px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.extra-small-dark-disabled {
  font-size: 12px;
  color: rgba(1, 7, 9, 0.35);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.extra-small-blue {
  font-size: 12px;
  color: #1e8ced;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.view-more-link {
  font-size: 14px;
  color: #1e8ced;
  margin-top: 0px !important;
  margin-bottom: 0px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
}

.view-more-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.normal-red-dark {
  font-size: 16px;
  color: #f60038;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.normal-green-dark {
  font-size: 16px;
  color: #008985;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.small-green-dark {
  font-size: 14px;
  color: #008985;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.small-red-dark {
  font-size: 14px;
  color: #f60038;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.extra-small-green-dark {
  font-size: 12px;
  color: #008985;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.extra-small-red-dark {
  font-size: 12px;
  color: #f60038;
  margin-top: 0px !important;
  margin-bottom: 0px;
}


.link-normal {
  font-size: 16px;
  color: #010709;
  margin-top: 0px !important;
  margin-bottom: 0px;
  font-weight: bold;
  cursor: pointer;
}

.link-normal:hover {
  color: #1e8ced;
}

.link-small {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;
  cursor: pointer;
}

.link-small:hover {
  color: #1e8ced;
}

.link-extra-small {
  font-size: 12px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;

  cursor: pointer;
}

.link-extra-small:hover {
  color: #1e8ced;
}

.helping-hand-wrapper {
  border-radius: 4px;
  border: solid 1px #6648ad;
  background-color: #f3f1f9;
  width: 100%;
  padding: 16px;
}

.helping-hand-text {
  margin-top: 0px !important;
  margin-bottom: 16px !important;
  color: #6648ad;
  font-weight: bold;
  text-align: center;
}

.notice-deatils p {
  font-size: 16px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;
}
