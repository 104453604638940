/************ App Login ************/

.applogin-cont {
  position: absolute;
  bottom: 0;
  padding: 60px 20px 20px;
  width: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 25%,
    rgba(0, 0, 0, 0.5) 50%,
    rgb(0, 0, 0) 100%
  );
}

.win-height {
  height: 100vh;
}

.logo {
  text-align: center;
  padding-top: 24px;
}
