/************** Super User *************/

.superuser-section {
  margin-left: 60px;
}

.Superuser-master-img {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  display: block;
}
.Superuser-master-img-2 {
  height: 200px;
  padding: 10px;
  width: 240px;
  margin-left: 20px;
  margin-top: 20px;
}
.allsuperuser-page {
  margin-left: 30px;
  margin-right: 15px;
}

.manage-account-field {
  width: 96% !important;
  margin: 0 auto;
  .e-input-group {
    width: 86% !important;
    padding: 9px;
    /* margin-top: 3px; */
    border-radius: 4px;
    &.e-control-wrapper {
      width: 86% !important;
      padding: 9px;
      /* margin-top: 3px; */
      border-radius: 4px;
    }
  }
}

.first-col-img {
  width: 100%;
  float: left;
}

.number-wrapper {
  position: relative;
  &:after,
  &:before {
    position: absolute;
    right: 16px;
    width: 20px;
    height: 0.9em;
    font-size: 18px;
    pointer-events: none;
    background: transparent;
    text-align: center;
  }
}

.hover-show {
  width: 40px;
  background: border-box;
  padding: 0px 10px;
  line-height: 30px;
  color: #fff;
  border-radius: 3px;
  background-color: #166ab3;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  position: relative;
  top: -18px;
}

.paid-icon-section {
  float: left;
  margin-right: 8px;
}

.invoice-icon-section {
  float: left;
}

.css-2o5izw input {
  &:focus {
    box-shadow: inset 0 0 0 0px #cccccc !important;
  }
  box-shadow: inset 0 0 0 0px #cccccc !important;
}

.overflow-initial {
  overflow: inherit !important;
}

.invoice-drawer-input {
  width: 94%;
  height: 20px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #00000038 !important;
  padding: 10px;
  margin-top: 15px;
}

.drawer-datepicker .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
  padding: 4px 8px !important;
}

.invoice-drawer-textarea {
  width: 94%;
  border-radius: 4px;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  border-color: #cccc !important;
}

.invoice-drawer-text input {
  padding: 10px;
}

.invoice-drawer-textarea:focus {
  box-shadow: inset 0 0 0 2px #166ab3;
  -webkit-box-shadow: inset 0 0 0 2px #166ab3;
}

.drawer-datepicker .e-date-wrapper {
  width: 95% !important;
}

.paid-icon {
  border: 1px solid #166ab3 !important;
  border-radius: 50px;
  padding: 0px;
  height: 20px;
  width: 20px;
  fill: #166ab3 !important;
  &:hover {
    fill: #fff !important;
    background: #166ab3;
  }
}

.invoice-action-button {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: #166ab3;
  text-align: center;
  float: left;
  margin-left: 8px;
  svg {
    fill: #fff;
    padding-top: 3px;
  }
}

.onhover {
  position: absolute;
  left: -20px;
  top: 13px;
  border: 1px solid #166ab3;
  border-radius: 50px;
  path {
    fill: #166ab3;
  }
}

.onshow {
  position: absolute;
  top: 16px;
}

.mobil-svg-hover:hover .mobil-svg-hover-show,
.mobil-svg-hover-show.onshow:hover {
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  font-size: 15px;
  /* width: 200px; */
  padding-bottom: 5px;
  visibility: visible;
}

.dir-emp-details:hover .mobil-svg-hover-show,
.dir-emp-links .mobil-svg-hover-show {
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  font-size: 12px;
  padding-bottom: 5px;
  visibility: visible;
}

.delete-details {
  background: #edf5fd;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  height: 20px;
  font-size: 16px;
  margin-top: 12px;
  position: absolute;
  width: 46%;
  padding: 3px !important;
  margin-left: 10px;
}

.mblwidth {
  width: 500px;
}

.leadView {
  background-color: #159bff;
  padding: 30px;
}

.ownerView {
  color: #ffffff;
}

.floatleftsuper {
  float: left;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 2%;
}

.dateRange .e-date-range-wrapper {
  width: 168px !important;
  border-color: #00000047 !important;
  height: 36px !important;
  border-radius: 4px !important;
  margin-top: 3px;
}

.superuserform-feature-description {
  textarea {
    border: none;
  }
  textarea:focus {
    border: none;
  }
}

.CountryEditCard {
  margin: 30px;
  height: 390px;
}

.CountryUnEditCard {
  margin: 8px;
  height: 235px;
}

.card-items-start-1 {
  padding: 20px;
  width: 360px;
}
