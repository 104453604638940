/************* Holiday ***********/

.holyday-delete-details {
  background: #edf5fd;
  border-radius: 20px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  height: 38px;
  font-size: 16px;
  margin-top: -5px;
  position: absolute;
  width: 185px;
  padding: 8px !important;
  margin-left: 10px;
  right: -5px;
}

.disabledswitch span[tabindex="-1"] {
  color: #166ab3 !important;
}

.disabledText span {
  color: #bfbfbf !important;
}

.rbc-date-cell {
  padding-left: 0;
  color: #757575;
  display: inline-block;
  text-align: center;
  min-height: 60px;
  line-height: 60px;
  a {
    padding: 30px;
  }
}

.rbc-row .rbc-date-cell:hover {
  background: #bfd7f1;
  ~ .rbc-row .rbc-row-segment {
    background: #bfd7f1;
  }
}

.rbc-date-cell.rbc-current {
  background: #67bf07;
  color: #fff !important;
  a {
    color: #fff !important;
  }
  &::after {
    content: "";
    position: absolute;
    color: #fff !important;
    background: #67bf07;
    z-index: -1;
    height: 100%;
    width: 14.2857%;
    margin-left: calc(-14.2857% + 5px);
  }
}

.rbc-row.rbc-month-header {
  display: flex;
}

.rbc-toolbar {
  background: #166ab3;
  padding: 20px 30px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 0;
}

.rbc-toolbar1 {
  background: #166ab3;
  padding: 20px 30px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 0;
}

.calender-head {
  display: inline-flex;
  color: #fff;
  position: absolute;
  right: 12px;
  svg {
    cursor: pointer;
  }
}

.rbc-month-view {
  width: 93%;
  margin: 0 auto;
  display: block !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.rbc-event-content {
  // display: none !important;
}

.rbc-event {
  height: 12px;
  width: 12px;
  border-radius: 20px !important;
  position: absolute;
  top: 1px;
}

.rbc-header {
  border-bottom: 1px solid #75757540;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.ismandatory-date {
  padding: 2px;
  color: #fff;
  background: #50e3c2;
}

.optional-date {
  background: #f5a623;
  padding: 2px;
  color: #fff;
}

.holiday-calendar .rbc-row-content {
  z-index: 1;
}
