.stepper-data {
  width: 100%;
  overflow-y: auto;
  max-height: 480px;
  overflow-x: hidden;
  padding-top: 0px;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

.stepper-scroller {
  width: 100%;
  overflow-y: auto;
  // max-height: 590px;
  overflow-x: hidden;
  padding-top: 30px;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
  // background: red;
}

.stepper-scroller-content {
  width: 60%;
}

.device-wrapper {
  width: 100%;
  margin-bottom: 20px;
  ul {
    width: 100% !important;
  }
}

.device-wrapper .right img {
  height: 400px;
}

.MuiStepLabel-alternativeLabel {
  margin-top: 4px !important;
}

.stepper-action-wrapper {
  position: fixed;
  left: 0px;
  background: white;
  bottom: 0px;
  padding: 10px;
  border-top: 1px solid rgb(212, 212, 212);
  z-index: 0;
  width: 100%;
}

.stepper-left-action {
  float: left;
  margin-left: 304px;
}

.stepper-right-action {
  float: right;
  margin-right: 72px;
}

.checklistDrawer .stepper-action-wrapper {
  position: absolute !important;
  background: #fff;
  width: calc(100% - 20px);
}

.checklistDrawer .stepper-action-wrapper .stepper-left-action {
  margin-left: 0px !important;
}

.checklistDrawer .stepper-action-wrapper .stepper-right-action {
  margin-right: 0px !important;
  margin-bottom: 14px;
}
