.custom-loader {
  margin: 80px auto;
  height: 80px;
  width: 80px;
  text-align: center;
  display: block;
}

.loader-outer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.planner-router-main .loader{
display: none !important;
}