/**********************Permission Profile****************/

.permissions-img {
  height: 280px;
  /* width: 100%; */
  margin: 0 auto;
  text-align: center;
  margin-bottom: -20px;
  border-radius: 50%;
}

.permissions-block {
  height: 300px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
  margin: 0 auto;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.permissions-subblock {
  border-radius: 4px;
  color: #fff;
  background: #166ab3;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}

.permissions-div {
  background: #e3e7f2;
  border-radius: 50%;
  border: 1.3px solid #166ab3;
  padding-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.permissions-div2 {
  border-radius: 2px;
  padding-top: 27px;
  padding-left: 30px;
  background-color: #166ab3;
}

.paddingtopleft {
  padding: 8px;
  background-color: #d1e1fb;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(14, 14, 14, 0.38);
  margin-top: 30px;
  border-radius: 4px;
}

.paddingtopleft2 {
  background-color: #166ab3;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  padding: 8px;
  margin-top: 30px;
}

.middelText {
  padding: 0px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 10px;
}

.tickColor {
  color: #7ed321;
  float: right;
  font-size: x-large;
}

.tickColor2 {
  color: #d0021b;
  float: right;
  font-size: x-large;
}

.actions {
  width: 115px;
  height: 119px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.06;
  letter-spacing: 1.4px;
  color: rgba(0, 0, 0, 0.38);
}
