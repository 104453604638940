.search-square {
  border: 1px solid #dcdcdc;
  padding: 4px 0px 4px 12px;
  border-radius: 4px;
  // width: 86% !important;
  height: 40px;
  background: #fff;
}

.search-round {
  border: 1px solid #dcdcdc;
  padding: 4px 12px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
}
