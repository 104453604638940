.current-step{
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 40px;
    border: 2px solid #1e8ced;
    font-size: 0.75rem;
    padding: 2px;
    color: rgba(0,0,0,0.87);
    font-weight: bold;
    display: block;
    line-height: 1.5em;
}

.disabled-step{
    width: 18px;
    height: 18px;
    background: #ccc;
    border-radius: 40px;
    border: 2px solid #ccc;
    font-size: 0.75rem;
    padding: 2px;
    color: rgba(0,0,0,0.87);
    font-weight: bold;
    display: block;
    line-height: 1.5em;
}

.completed-step{
    width: 18px;
    height: 18px;
    background: #1e8ced;
    border-radius: 40px;
    border: 2px solid #1e8ced;
    font-size: 0.75rem;
    padding: 2px;
    color: #fff;
    font-weight: bold;
    display: block;
    line-height: 1.5em;
}

.MuiStepConnector-lineVertical{
    border-left-style: dashed !important;
    border-color:#1E8CED !important
}

.active-vertical-step{
    padding-bottom: 16px !important;
    border: solid 1px #1E8CED !important;
}

.vertical-step-wrapper{
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #d9dadb;
    padding: 16px 24px 0px 24px;
    margin-bottom: 8px;
}

.active-step-label,.inactive-step-label{
    font-size: 16px !important;
    color: rgb(1, 7, 9) !important;
    font-weight: bold !important;
    letter-spacing: 0px !important;
    margin-left: 8px;
}

.inactive-step-label{
    color: rgba(1, 7, 9, 0.7) !important;
}