/* Fade Out Effect css */
@keyframes bottomFadeOut {
  0% {
    position: relative;
    bottom: 0%;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.emailAnimation {
  position: relative;
  bottom: 134%;
  left: 2%;
  opacity: 1;
  padding: 0px;
  color: white;
  background-color: black;
  -webkit-animation-name: bottomFadeOut;
  animation-name: bottomFadeOut;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  width: 70px;
  border-radius: 6%;
  transition: all ease-in-out;
  z-index: 1;
  font-size: small;
}

.animatedText {
  position: relative;
  bottom: 100%;
  left: -10%;
  opacity: 0;
  padding: 4px;
  color: white;
  background-color: black;
  -webkit-animation-name: bottomFadeOut;
  animation-name: bottomFadeOut;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  width: 63px;
  border-radius: 6%;
  font-size: smaller;
  transition: all ease-in-out;
}
