.config-landing-content-container{
  padding: 0px;
}
.config-landing-content-container .p{
  color: rgba(0, 0, 0, 0.38);
  padding: 20px 20px 20px 0px;
  text-align: justify;
}


.mainprofile-minheight .config-landing-content-container .p{
  color: rgba(0, 0, 0, 0.38);
  padding: 20px 20px 20px 0px;
  text-align: left !important;
}


.config-landing-content-container .createbutton{
  width: 170px;
  height: 55px;
}

.config-landing-content-container .addbutton{
  width: 120px !important;
  height: 40px !important;
  background: #166ab3 !important;
  font-weight: bold;
  box-shadow: 0 0 0 !important;
}

.breadcrum-container{
  padding-left: 0px;
}
.breadcrum-container .icon{
  display: inline-flex;
  vertical-align: middle;
  padding-right: 30px;
}

.config-landing-content-container .right{
  text-align: center;
}

.config-table{
  /* width: 90% !important; */
  margin: 0 auto;
}

.config-table .list-table{
  overflow-x: auto;
  overflow-y: hidden;
}

  .config-table .createbutton{
    width: 128px;
    height: 40px;
    font-weight: bold; 
    background-color: #1e8ced !important;
    font-size: 16px !important;
    height: 40px;
    box-shadow: 0 0 0 !important;
  }

.config-table table thead th, .config-table table tbody td{
  text-align: center;
  padding: 4px 16px 4px 16px !important;
  color: rgba(1, 7, 9, 0.70);
  font-size: 14px;
  font-weight: 400;
}

.config-table table thead th{
  padding: 0px 15px !important;
  border-bottom: 0px !important;
}

.config-table .searchBox{}
.config-table .searchBox:nth-child(1):hover {
  border-bottom: 0px soild ;
}
.config-table .searchBox:nth-child(1):hove {
    border-bottom: none;
}
.config-table .searchBox:nth-child(1) ::before{
  border-bottom: none !important;
}
.config-table .searchBox:nth-child(1) ::after{
  border-bottom: none !important;
  background: red;
}


.config-table .noOfResults{
  margin-right: 60px;
  color: rgba(1, 7, 9, 0.35);
  font-size: 14px;
}

.config-table tr td .td-avatar{
  height: 40px;
  width: 40px;
}

.config-table .table-search-container{
  width: 100%
}


@media  (max-width: 1050px) {
  /* For general iPad layouts */
  .config-table{
    width: 98% !important;
    margin: 0 auto;
  }
  .config-table tr td .td-avatar{
    height: 25px;
    width: 25px;
  }
}

@media  (max-width: 960px) {
  /* For general iPad layouts */
  .config-table{
    width: 98% !important;
    margin: 0 auto;
  }
  .config-table .sort-by-margin{
    margin-top: 5px;
  }
  .config-table .noOfResults{
    font-size: 17.3px;
    margin-right: 0px;
  }
  .config-table tr td .td-avatar{
    height: 30px;
    width: 30px;
  }

}

.list-data-preview{
  cursor: pointer;
  position: relative;
}

.zoom-icon, .list-doc-img{
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.zoom-icon{
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
}

.zoom-icon img{
  position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  margin: auto;
}

.list-data-preview:hover .zoom-icon{
opacity: 1;
}
