/*************** Attendence Cycle ************/

.cycle-desc {
  width: 500px;
  height: 252px;
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.46429em;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.38);
}

.innerwrapper {
  &.requestcycle {
    width: auto !important;
  }
}

.configure-form ul {
  /* width: 73.5% !important; */
  width: 100% !important;
}

.requestCycleLabels {
  /* height: 19px; */
  font-size: 16px;
  /* font-weight: 500; */
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: justify;
  color: #000000;
}

.labelsSize {
  /* font-size: 20px !important; */
  font-size: 16px !important;
}

.requestCycleLabelsValue {
  height: 23px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #166ab3;
}

.reminderCol {
  color: rgba(6, 6, 6, 0.6) !important;
}

.selectWidth {
  width: 500px !important;
}

.selectWidthReminder {
  width: 75px !important;
  height: 40px !important;
}

.selectDivHeight {
  width: 96%;
  background-color: #f4f4f4;
  padding-bottom: 10px;
  margin: (-20px) auto 20px auto;
}

.Note {
  color: #000000;
  height: 19px;
  font-size: 15.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: justify;
}

.shift-planner-form .selectsearch {
  width: 72% !important;
  .css-vj8t7z {
    min-height: 38px;
  }
}

/********** Attendance Cycle n Leave Plan*************/

.positionsticky {
  position: sticky;
}

.Select-the-Long-Leav {
  font-size: 16.3px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  color: #166ab3;
}
