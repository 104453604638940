.react-calendar {
  font-family: "muli", sans-serif !important;
}

.react-daterange-picker__inputGroup,
.react-date-picker__inputGroup {
  padding: 0 2px !important;
}

.react-daterange-picker__button,
.react-date-picker__button {
  padding: 4px 2px !important;
}

.react-daterange-picker__calendar,
.react-date-picker__calendar {
  width: 284px !important;
  max-width: 284px !important;
  border-radius: 4px;
  top: 100% !important;
  bottom: unset !important;
}

.shift-planner-outer .react-date-picker__calendar {
  top: 100% !important;
  bottom: unset !important;
}

.drawer-container .react-date-picker__calendar {
  // top: 100%;
  // bottom: 100%;
}

.react-daterange-picker__inputGroup__input,
.react-date-picker__inputGroup__input {
  pointer-events: none;
  // height: 20%;
}

.react-daterange-picker__inputGroup__input:invalid {
  background: transparent !important;
}

.react-date-picker__wrapper,
.react-daterange-picker__wrapper {
  font-size: 14px !important;
  padding: 8px 8px 6px 8px !important;
  border: 0px !important;
  border-radius: 4px;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon {
  stroke: #1e8ced !important;
}

.react-date-picker__inputGroup__input:invalid {
  background: #fff !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #1e8ced !important;
}

.react-date-picker * {
  text-decoration: none !important;
}

.react-daterange-picker__button svg,
.react-date-picker__button svg {
  height: 16px !important;
  width: 16px !important;
  stroke: #545556 !important;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7 !important;
}

.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #0078d7 !important;
}

.react-daterange-picker * {
  text-decoration: none !important;
}

abbr[title] {
  text-decoration: none !important;
}

.react-daterange-picker__inputGroup__input {
  color: #010709 !important;
}

.react-calendar button {
  font-size: 12px !important;
  letter-spacing: 0px !important;
}

.react-calendar__tile--hasActive {
  background: #1e8ced !important;
  color: #fff !important;
}

.react-calendar {
  // -webkit-box-shadow: 1px 1px 10px 10px rgba(0,0,0,0.1);
  // box-shadow: 1px 1px 10px 10px rgba(0,0,0,0.1);
  border: thin solid #cacaca !important;
}

.react-daterange-picker__range-divider {
  position: relative;
  top: 2px;
}

.react-daterange-picker,
.react-date-picker {
  width: 100%;
  border: solid 1px #cacaca;
  border-radius: 4px;
  height: 40px;
}

.react-daterange-picker__clear-button,
.react-date-picker__clear-button {
  position: absolute;
  right: 32px;
  padding: 3px 2px !important;
}

.react-daterange-picker__calendar-button,
.react-date-picker__calendar-button {
  position: absolute;
  right: 8px;
}

.react-date-picker__inputGroup {
  min-width: 150px;
}

.react-date-picker__inputGroup__input:invalid {
  background: #fff !important;
}

.react-calendar__navigation button {
  background: none;
  min-width: 38px !important;
}

.react-calendar__navigation__arrow {
}

.react-calendar__navigation__label {
  min-width: 90px !important;
}

.calenderTop {
  top: 100% !important;
  bottom: unset !important;
}
