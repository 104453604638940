
/************** Attendance Log *************/

.attendancelog-menu {
  right: 120px;
  width:200px;
}

.attendancelog-textarea {
  width: 93%;
  height: 70px;
  padding: 10px;
  resize: none;
  border-radius: 4px;
  &:focus {
    -webkit-box-shadow: inset 0 0 0 2px #166ab3;
    box-shadow: inset 0 0 0 2px #166ab3;
  }
}

.attendancetimeinput {
  width: 160px;
  font-size: 20px;
}