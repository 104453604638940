/*********** Weekly Off ************/

.parent-selection.depedent-select-list[aria-expanded="true"] {
  background-color: #2ecaff29;
  box-shadow: 0 7px 5px 1px rgba(0, 0, 0, 0.16);
  position: relative;
  border-top: none;
}

.depedent-select-list {
  &.no-bg[aria-expanded="true"] {
    background-color: #fff;
  }
  &.bg[aria-expanded="false"] {
    background-color: #2ecaff29;
  }
}

.first-level-collapse {
  background: #f4f4f4;
  margin: 0 16px;
  padding: 20px;
}

.second-level-collapse {
  background: #fbfbfb;
  margin: 0 16px;
  padding: 20px;
}

.third-level-collapse {
  background: #fff;
  margin: 0 16px;
  padding: 20px;
}

.no-top-border {
  border-top: none;
}

.weekoff-number {
  padding: 6px;
  width: 70px;
  background: transparent;
  border: 1px solid rgba(1, 1, 1, 0.6);
  font-size: 16px;
  border-radius: 4px;
  color: rgba(1, 1, 1, 0.6);
  &:focus {
    border: 1px solid #166ab3;
  }
}

.number-of-weekly-off {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  color: #000000;
  margin-left: 15px;
}

.select-number {
  height: 90px;
  text-align: center;
  font-size: 16px;
  line-height: 90px;
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  margin-top: 1px;
}

.select-weekoff {
  height: 90px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  display: table;
  margin-top: 1px;
}

.week-off-slider-new {
  position: relative;
  top: 20px;
}

.weekoff-day {
  width: 12.2%;
  display: inline-block;
  text-align: center;
  margin-left: 0px;
  position: relative;
  border-left: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
}

.weekoff-slider {
  width: 60px !important;
  margin: 0 auto;
  top: 28px;
}

.noholiday {
  background-color: #eeeeee;
}

.halfholiday {
  background-color: #ffecb3;
}

.fullholiday {
  background-color: #c5cae9;
}

.weekdays {
  padding: 10px;
  font-size: 16px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
  background: rgb(226, 241, 255);
}

.MuiSlider-trackAfter-300,
.MuiSlider-trackAfter-376,
.weekoff-slider div:nth-child(2) {
  opacity: 1 !important;
}

.MuiSlider-track-298,
.MuiSlider-track-374 {
  background-color: #fff !important;
  border-radius: 20px;
  height: 18px !important;
}

.weekoff-slider {
  div {
    &:last-child {
      background-color: #fff !important;
      border-radius: 20px;
      height: 18px !important;
    }
    &:nth-child(2),
    &:nth-child(1) {
      background-color: #fff !important;
    }
    background-color: #fff !important;
  }
  button {
    height: 20px !important;
    width: 20px !important;
    margin-top: 9px;
  }
}
