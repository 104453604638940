/************* Slider ***********/

.slide {
  width: 100%;
  height: 400px;
  position: absolute;
  overflow: hidden;
}

.track {
  position: relative;
}

.slider-wrapper {
  width: 100%;
  height: 350px;
  overflow: hidden;
  position: relative;
}

.slider-wrapper .previousButton,
.slider-wrapper .nextButton {
  bottom: 0px;
}

.slider {
  width: 2000px;
}

.hide {
  float: left;
  position: relative;
  text-transform: uppercase;
  color: #fff;
  line-height: 250px;
  font-size: 20px;
  background: #383838;
  text-align: center;
  border-radius: 4px !important;
  transition: all 0.2s linear;
}

.slider-wrapper {
  svg polygon {
    fill: #fff !important;
    cursor: pointer;
  }
  .nextButton {
    position: absolute;
    left: 22px;
    bottom: 15%;
    z-index: 11;
    height: 40px;
    cursor: pointer;
  }
  .previousButton {
    position: absolute;
    left: 0;
    bottom: 15%;
    z-index: 11;
    height: 40px;
    cursor: pointer;
  }
}

.nextButton svg,
.previousButton svg,
.prev-button,
.next-button {
  width: 10px !important;
}

.MuiModal-root-80 {
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}
