/********************Leave transition*********/

.transition-desc {
  width: 500px;
  height: 79px;
  font-size: 16.3px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.72;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.38);
}

.ColorBlack {
  color: #000000 !important;
}

.cycleList .MuiMenuItem-root-22 {
  width: 800px !important;
}

.leave-transition {
  .e-control-wrapper.e-slider-container.e-horizontal {
    height: 50px !important;
  }
  .e-slider-tooltip.e-tooltip-wrap.e-popup.e-tooltip-wrap.e-popup.e-material-default {
    background-color: #166ab3 !important;
  }
}

.e-slider-tooltip.e-tooltip-wrap.e-popup.e-tooltip-wrap.e-popup.e-material-default {
  background-color: #166ab3 !important;
}
