/********** Salary Structure ***********/

.steps-heading {
  font-size: 20.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: justify;
  color: #000000;
}

.selected-comp-label {
  font-size: 16.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.selected-icon {
  font-size: 20px;
  color: #fff;
}

.selected-comp {
  border-radius: 4px;
  background-color: #166ab3;
  padding: 15px;
  text-align: center;
}
