$i :unquote("!important");
@mixin text($size:null, $lineheight:null, $weight:null, $font-family:null,$text-align:null) {
     @if $size != null {
    font-size: $size $i;
  }
  @if $text-align{
    text-align: $text-align;
  }
   @if $lineheight != null {
    line-height: $lineheight $i;
  }
   @if $weight != null {
    font-weight: $weight $i;
  }

}

@mixin imageStyle($height:null, $width:null, $border-radius:null,$border:null,$padding:null,$object-fit:null){
  @if $height!=null{
    height:$height $i;
  }
  @if $width!=null{
    width:$width $i;
  }
  @if $border-radius!=null{
    border-radius:$border-radius $i;
  }
  @if $border!=null{
    border:$border $i; 
  }
  @if $padding!=null{
    padding:$padding $i
  }
  @if $object-fit{
    object-fit: $object-fit $i
  }
}
@mixin boxShadow($h-offset:null,$v-offset:null,$blur-radius:null,$spread:null,$color:null) {
  $h-offet:$h-offset;
  $v-offset:$v-offset;
  $blur-radius:$blur-radius;
  $spread:$spread;
  $color:$color;
  @if $h-offset!=null{
    @if $v-offset!=null{
      @if $blur-radius!=null{
        @if $spread!=null{
          @if $color!=null{
                box-shadow:($h-offet $v-offset $blur-radius $spread $color);
            }
        }
      }
    } 
  }
}
@mixin heightWidth($width:null, $height:null, $min-height:null, $min-width:null) {
  @if $width!=null{
    width: $width $i
  }
  @if $height!=null{
    height:$height $i
  }
  @if $min-height!=null{
    min-height:$min-height $i
  }
  @if $min-width!=null{
    min-width:$min-width $i
  }
}
@mixin margin($margin-top:null, $margin-right:null,$margin-bottom:null,$margin-left:null){
  @if $margin-top!=null{
    margin-top: $margin-top
  }
  @if $margin-left!=null{
    margin-left:$margin-left
  }
  @if $margin-bottom!=null{
    margin-bottom:$margin-bottom
  }
  @if $margin-right!=null{
    margin-right:$margin-right
  }
}
@mixin padding($padding-top:null, $padding-right:null,$padding-bottom:null,$padding-left:null)
{
   @if $padding-top!=null{
    padding-top: $padding-top
  }
  @if $padding-left!=null{
    padding-left:$padding-left
  }
  @if $padding-bottom!=null{
    padding-bottom:$padding-bottom
  }
  @if $padding-right!=null{
    padding-right:$padding-right
  }
}
