/********** SignUp **************/

.lead-signup-form {
  padding: 20px 20px;
  margin: 0 auto;
  width: 328px;
  background: white;
  border-radius: 5px;
}

.lead-signup-form-wide {
  // padding: 32px 40px;
  padding: 16px 16px;
  margin: 0 auto;
  // width: 536px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  // height: 370px;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  /* z-index: 0; */
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}

.input-div {
  height: 80px;
  position: relative;
}

.sucessGif img {
  height: 300px;
}

.caption-error ~ .react-tel-input {
  border: 2px solid red;
  border-radius: 4px;
  input {
    border: none !important;
  }
}

.phone-input-error {
  position: absolute;
  bottom: -16px;
}

.react-tel-input:focus input:focus-within {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  pointer-events: none;
}

input:-webkit-autofill {
  background-color: #fff !important;
}

.signup-up-button-padding {
  padding-bottom: 15px;
  height: 50px;
}

.chechbox-label {
  display: inline-block;
  word-break: break-word;
}

//////////////////////////////////////// Forgot Password Css//////////////////////////////////////////////////

.HRMSimplified-div {
  background-image: radial-gradient(circle at 100% 100%, #2ecaff, #166ab3);
  height: 100vh;
}

.signup-right-side {
  display: block;
}

.leadloginformdiv {
  height: 100vh;
  // display: table;
}

.lead-forgetpassword {
  float: right;
  padding-top: 8px;
  text-align: right;
  font-size: 14px;
}
