.review-period-monthly {
  width: 200px;
  height: 150px;
  border-radius: 4px;
  background-color: #fff;
  float: left;
  margin-right: 16px;
  position: relative;
}

.review-period-quarterly {
  width: 200px;
  height: 150px;
  border-radius: 4px;
  background-color: #fff;
  float: left;
  margin-right: 16px;
  position: relative;
}

.rating-scale-selector {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px transparent;
  position: relative;
}

.rating-scale-selector:hover {
  border: solid 1px #d9dadb;
}

.rating-scale-selected {
  border: solid 1px #1e8ced;
}

.emoji-picker {
  width: 38px;
  height: 38px;
  border-radius: 4px;
  border: solid 1px #d9dadb;
  position: relative;
  margin-top: 2px;
}

.active-section-tab {
  color: #1e8ced;
  font-weight: bold;
}

.inactive-section-tab {
  color: rgba(1, 7, 9, 0.7);
  font-weight: bold;
}

.review-form-section-tab {
  list-style-type: none;
  cursor: pointer;
}

.light-grey-bg {
  background: #f7f8f8;
  padding: 16px;
  box-sizing: border-box;
}

.rating-scale-types {
  width: 24px;
  height: 24px;
  background: #d9dadb;
  float: left;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
  line-height: 24px;
  margin-right: 12px;
  border: solid 2px transparent;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}

.rating-scale-types-selected {
  width: 24px;
  height: 24px;
  background: #dcf2f1;
  float: left;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
  line-height: 24px;
  margin-right: 12px;
  border: solid 2px #1e8ced;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  color: #008985;
}

.rating-scale-points,
.rating-scale-points-selected,
.rating-scale-points-disabled {
  min-width: 14px;
  height: 14px;
  border-radius: 4px;
  background-color: #fff;
  color: rgba(1, 7, 9, 0.35);
  padding: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 8px;
  float: left;
  border: 1px solid #d9dadb;
  text-align: center;
  line-height: 14px;
}

.rating-scale-points-selected {
  color: #fff !important;
  background-color: #1e8ced !important;
  border: 1px solid #1e8ced;
  font-weight: bold;
}

.rating-scale-border-selected {
  border: 1px solid #1e8ced;
}

.rating-scale-points:hover {
  border: 1px solid #008985;
  color: #008985;
}

.rating-scale-view,
.comment-section {
  display: none;
}

.question-section:hover .rating-scale-view {
  display: block;
}

.question-section:hover .comment-section {
  display: flex;
}
