html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: "Muli", sans-serif !important;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
  div,
  select,
  input,
  label,
  button,
  p {
    outline: none;
  }
}

/////////////////////////// Slider ScrollBar All Application Scroll //////////////////////////////////////////

body::-webkit-scrollbar-thumb,
.navsidebarsection-height::-webkit-scrollbar-thumb,
.navsidebarsection-height1::-webkit-scrollbar-thumb,
.requestcenter-form::-webkit-scrollbar-thumb,
.request-center-drawer::-webkit-scrollbar-thumb,
.MuiModal-root-80::-webkit-scrollbar-thumb,
.verticaltab-innercont::-webkit-scrollbar-thumb,
.request-cenetr-newemp::-webkit-scrollbar-thumb,
.stepper-scroller::-webkit-scrollbar-thumb,
.quoat-text::-webkit-scrollbar-thumb,
.drawer-form::-webkit-scrollbar-thumb,
.dp-data::-webkit-scrollbar-thumb,
#div::-webkit-scrollbar-thumb,
.main-contianer::-webkit-scrollbar-thumb,
.expense-types::-webkit-scrollbar-thumb,
.sub-container-outer::-webkit-scrollbar-thumb,
.notification-scroll::-webkit-scrollbar-thumb,
.grid-two::-webkit-scrollbar-thumb,
.main-container::-webkit-scrollbar-thumb,
.notification-ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  background-color: #555;
}

body::-webkit-scrollbar,
.navsidebarsection-height::-webkit-scrollbar,
.navsidebarsection-height1::-webkit-scrollbar,
.requestcenter-form::-webkit-scrollbar,
.request-center-drawer::-webkit-scrollbar,
.MuiModal-root-80::-webkit-scrollbar,
.verticaltab-innercont::-webkit-scrollbar,
.request-cenetr-newemp::-webkit-scrollbar,
.stepper-scroller::-webkit-scrollbar,
.quoat-text::-webkit-scrollbar,
.drawer-form::-webkit-scrollbar,
.dp-data::-webkit-scrollbar,
#div::-webkit-scrollbar,
.main-contianer::-webkit-scrollbar,
.expense-types::-webkit-scrollbar,
.sub-container-outer::-webkit-scrollbar,
.notification-scroll::-webkit-scrollbar,
.grid-two::-webkit-scrollbar,
.main-container::-webkit-scrollbar,
.notification-ul::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

body::-webkit-scrollbar,
#div1::-webkit-scrollbar {
  width: 0;
}

.config-mode-drawer::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
  border-radius: 10px;
}

.config-mode-drawer {
  // height: 100vh;
  // overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

.config-mode-drawer::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

.config-mode-paper {
  // height: 92vh !important;
  // overflow-y: auto !important;
  background-color: #010709 !important;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
  // position: relative !important ;
  border-right: 0px !important;
  margin-top: 56px;
  width: 250px;
  height: calc(100% - 56px) !important;
}

.config-mode-paper1 {
  // height: 92vh !important;
  // overflow-y: auto !important;
  background-color: #010709 !important;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
  // position: relative !important ;
  border-right: 0px !important;
  margin-top: 56px;
  width: 250px;
  height: calc(100% - 56px) !important;
}

.config-mode-paper-closed {
  // height: 92vh !important;
  // overflow-y: auto !important;
  background-color: #010709 !important;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
  // position: relative !important ;
  border-right: 0px !important;
  margin-top: 56px;
  width: 64px;
  height: calc(100% - 56px) !important;
}

.normal-mode-paper {
  position: relative !important;
  border-right: 0px !important;
  margin-top: 56px;
  position: fixed !important;
  background: #f7f8f8 !important;
  height: calc(100% - 56px) !important;
}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  width: 65px;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////

.ExitReasonTextbox {
  input {
    height: 15px;
  }
}

.rectangle-paper {
  min-height: 54px;
  border-radius: 0px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px rgba(217, 218, 219, 0.5);
}

.rounded-rectangle-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px rgba(217, 218, 219, 0.5);
}

.doughnut-chart .chartjs-render-monitor {
  height: 191px !important;
  width: 288px !important;
}

.bar-chart .chartjs-render-monitor {
  height: 198px !important;
  width: 310px !important;
}

.bar-chart-joining .chartjs-render-monitor {
  height: 70% !important;
  width: 98% !important;
}

.leaves-breakdown-chart .chartjs-render-monitor {
  height: 174px !important;
  width: 261px !important;
}

.doughnut-chart-attendance .chartjs-render-monitor {
  height: 174px !important;
  width: 264px !important;
  display: inline-block !important;
}

.doughnut-chart-attendance-data {
  padding-left: 50px;
  position: relative;
}

.doughnut-chart-attendance-data .chartjs-render-monitor {
  height: 174px !important;
  width: 264px !important;
  margin-top: 10px;
  display: inline-block !important;
}

.hide-chart {
  background: #fff;
  position: absolute;
  top: 50px;
  left: 184px;
  width: 130px;
  height: 130px;
}

.form-height {
  height: 100% !important;
  padding-top: 0px;
}
