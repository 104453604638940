/***********PayRoll****/

.PayRoll-form {
  input,
  textarea,
  ul,
  select,
  .depedent-select-list {
    width: 71.49% !important;
  }
}

.shift-planner-form .selectsearch {
  width: 74% !important;
}

/***********payroll Component***********/

.component-label {
  font-size: 20px;
  font-weight: normal;
  text-align: justify;
  color: #000000;
}

.payroll-component-top-head {
  background-color: #f8f8f8;
  text-align: center;
}

.payroll-component-vertical-divider {
  border-left: solid 1px #ececec;
}

.payroll-component-body-head {
  border-radius: 4px;
  background-color: #eef4fd;
  padding: 15px;
  text-align: center;
}

.component-heading {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  width: 135px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin: 0 auto;
}

.component-no {
  width: 45px;
  height: 45px;
  background-color: #76b7ff;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  font-weight: 500;
  margin-top: -35px;
  margin-left: -22px;
}

.component-icon {
  position: absolute;
  margin-top: -35px;
  right: 0;
  display: none;
}

.payroll-component-body-head:hover .component-icon {
  display: block;
}

.block-padding {
  padding: 15px 15px 15px 30px;
}

.right-side-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.6px;
  color: #047cff;
  width: 60px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.payroll-component-btn {
  font-size: 11.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: #ffffff;
}

.yes-btn {
  padding: 2px 15px 2px 15px;
  border-radius: 7px;
  background-color: #7ed321;
}

.no-btn {
  padding: 2px 17px 2px 17px;
  border-radius: 7px;
  background-color: #d0021b;
}

.pay-structure-input {
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #a5a5a5;
  min-height: 40px;
}

.pay-structure-title {
  width: 95%;
  font-size: 15px;
  padding: 3px;
  background-color: #d8d8d8;
  font-size: 14.3px;
  font-weight: 500;
  text-align: justify;
  color: rgba(0, 0, 0, 0.38);
}

.pay-structure-sub-item {
  padding: 10px;
  min-width: 50px;
  text-align: center !important;
  border-radius: 4px;
  border: 1px solid #979797;
  background-color: #e0e0e0;
  color: #8a8a8a;
  font-size: 16.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: justify;
  color: #8a8a8a;
  cursor: pointer;
  outline: none;
}

.pay-structure-signs {
  padding: 10px;
  min-width: 30px;
  text-align: center !important;
  border-radius: 4px;
  border: 1px solid #979797;
  font-size: 18.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: justify;
  color: #4a90e2;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: #166ab3;
    color: #fff;
  }
}
.list-color-style {
  height: 8px;
  width: 8px;
  background: #166ab3;
  border-radius: 50%;
  position: absolute;
  margin-top: 5px;
  margin-left: -14px;
}

/***********payroll cycle***********/

.side-divider {
  height: 1px;
  width: 100%;
  position: relative;
  background: #d9dadb;
  top: 12px;
}

.heading-with-divider {
  background: #fff;
  position: relative;
  padding: 0 16px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(1, 7, 9, 0.7);
}

.custom-table-payroll thead tr {
  height: 40px;
  background: #f7f8f8;
  border-bottom: 2px solid #d9dadb;
}

.custom-table-payroll th {
  text-align: center !important;
  padding: 0px !important;
}

.custom-table-payroll tbody tr {
  height: 40px;
  background: #fff;
}

.custom-table-payroll tbody tr:hover {
  background: #dcf2f1;
}

.custom-table-payroll tr td {
  padding: 0px !important;
  text-align: center;
}

.dash {
  width: 12px;
  height: 2px;
  background: rgb(151, 151, 151);
  display: block;
  margin: 0px auto;
}

/***********payroll salary component***********/

.salary-component {
  width: 200px;
  height: 75vh;
  margin-right: 16px;
  display: inline-block;
  white-space: normal;
  position: relative;
}

.salary-component-active {
  width: 200px;
  height: 75vh;
  margin-right: 16px;
  display: inline-block;
  white-space: normal;
  border: 1.5px solid #057cff;
}

.search-component {
  width: 92%;
  display: block;
  margin-left: 8px;
  padding-bottom: 9px;
}

.empty-state {
  height: 44vh;
  text-align: center;
  position: relative;
}

.empty-state-details {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.salary-component-config {
  height: 42vh;
  position: relative;
  overflow: hidden;
  padding: 4px 0 4px 0px;
  background: #edf5fd;
}

.salary-component-details {
  height: 98%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 4px;
  padding-bottom: 0px;
}

.salary-component-name {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  color: rgba(1, 7, 9, 0.7);
  text-align: center;
  font-weight: bold;
  padding: 2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.component-name {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  color: rgb(1, 7, 9);
  font-weight: bold;
}

.seleted-component-details {
  border-left: solid 2px #edf5fd;
  padding-left: 8px;
}

.seleted-component-details-form {
  border-left: solid 2px #edf5fd;
  padding-left: 16px;
}

.salary-component-data {
  height: 85%;
  position: relative;
  overflow: hidden;
}

.salary-component-wrapper {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 16px;
}

.selected {
  border: 1px solid #1e8ced;
}

.component-tab {
  background: #f7f8f8;
  height: 100%;
}

.left-tab-button {
  border-bottom: none !important;
  text-align: left !important;
  min-height: 34px !important;
  padding: 4px 4px 4px 16px !important;
  letter-spacing: normal !important;
  text-transform: capitalize !important;
}

.salary-component-list-item {
  padding: 4px 16px !important;
}

.list-item-text-subheading span {
  font-size: 14px;
  color: rgba(30, 140, 237, 0.7) !important;
  font-weight: bold !important;
}

.list-item-text-subheading {
  padding: 0px !important;
}

.salary-component-list-item-text {
  padding: 0 8px !important;
}

.salary-component-list-item-text span {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7) !important;
}

.salary-component-paper {
  width: 224px;
  max-height: 300px;
  overflow-y: auto;
  padding: 4px 0px;
}

.formula-input {
  width: 100%;
}

.formula-input input {
  padding: 15px !important;
}

.recurring-config-details {
  flex-direction: column;
}

.recurring-config {
  margin-top: 24px;
  background: #f7f8f8;
  padding: 8px;
}

.lop-table tbody tr {
  background: #fff;
  height: 24px;
}

.lop-table tbody tr:hover {
  background: #f7f8f8;
}

.lop-checkbox {
  padding: 3px !important;
}

.or-option {
  width: 32px;
  height: 32px;
  background-image: linear-gradient(149deg, #fcfcfc, #ededed 100%, #d3d3d3);
  color: #1e8ced;
  border-radius: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  margin: 0 auto;
}

.slip-design-element,
.slip-design-element-active {
  width: 224px;
  height: 141px;
  opacity: 0.7;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #d9dadb;
  background-color: #fff;
  padding: 16px;
  margin-bottom: 40px;
  position: relative;
}

.slip-design-element-active {
  border: solid 1px #1e8ced;
}

.logo-dummy {
  width: 60px;
  height: 20px;
  background-color: #d8d8d8;
  padding: 4px;
  color: rgba(1, 7, 9, 0.35);
  font-size: 12px;
  line-height: 20px;
  display: block;
  text-align: center;
}

.dummy-line {
  width: 60px;
  height: 5px;
  background-color: #d8d8d8;
  display: block;
  margin-bottom: 4px;
}

.dummy-body {
  width: 80%;
  height: 55px;
  margin-top: 43px;
  margin-left: auto;
  margin-right: auto;
  background-color: #d8d8d8;
  display: block;
}

.dummy-body1 {
  width: 100%;
  height: 55px;
  margin-top: 43px;
  background-color: #d8d8d8;
  display: block;
}

.dummy-line1 {
  width: 46px;
  height: 5px;
  background-color: #d8d8d8;
  display: block;
  margin-bottom: 4px;
  position: relative;
}

.file-upload-progress svg {
  width: 16px !important;
  height: 16px !important;
}

.file-upload-progress {
  width: 16px !important;
  height: 16px !important;
}

.edit-div {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  text-align: center;
}

.setup-block-wrapper {
  width: 504px;
  height: 87px;
  background-color: #f7f8f8;
  padding: 2px 0px 0px 8px;
  position: relative;
}

.setup-block {
  width: 176px;
  height: 47px;
  border-radius: 4px;
  border: solid 1px $Linen;
  background-color: #fff;
  float: left;
  margin-right: 8px;
}

.annual-package-info {
  opacity: 0;
}

.annual-package:hover .annual-package-info {
  opacity: 1;
}

.monthly-package-info {
  opacity: 0;
}

.monthly-package:hover .monthly-package-info {
  opacity: 1;
}

.gross-pay-info {
  opacity: 0;
}

.gross-pay:hover .gross-pay-info {
  opacity: 1;
}

.cost-info {
  opacity: 0;
}

.cost:hover .cost-info {
  opacity: 1;
}

.payroll-employee-list {
  padding: 8px 15px;
  border-bottom: 1px dashed #979797;
  border-top: 1px dashed transparent;
  background: #fff;
  height: 38px;
}

.employee-list-row {
  padding: 8px 15px;
  border-bottom: 1px dashed #979797;
  border-top: 1px dashed transparent;
  background: #fff;
  margin-left: -15px;
  margin-right: -15px;
  height: 38px;
}

.employee-list-row:hover {
  background: #f7f8f8;
  border-top: 1px dashed #979797;
}

.employee-img {
  display: block;
}

.checkbox-div {
  display: none;
}

.employee-list-row:hover .checkbox-div {
  display: block;
}

.employee-list-row:hover .employee-img {
  display: none;
}

.payroll-list-footer-btn {
  background: #fff;
  position: absolute;
  bottom: 30px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(217, 218, 219, 0.5);
  width: 100%;
  left: 0;
}

.additional-details-wrapper {
  height: 75vh;
  overflow-y: auto;
}

.data-div-disabled {
  text-align: left;
  border-radius: 4px;
  border: solid 1px #d9dadb;
  background-color: #f7f8f8;
  padding: 8px 16px;
}

.payroll-data-table {
}

.payroll-data-table td {
  padding: 4px 8px !important;
  font-size: 0.75rem !important;
}

.payroll-data-table th {
  padding: 6px 8px !important;
}

.details-list {
  background: #fff;
  border-bottom: 1px dashed #979797;
  padding: 8px 15px;
  min-height: 43px;
}

.details-list-expanded {
  background: #f7f8f8;
  border-bottom: 1px dashed #979797;
  padding: 8px 15px;
  min-height: 43px;
}

.details-list:hover {
  background: #f7f8f8;
}

.info-row:hover .checkbox-div {
  display: block;
}

.info-row:hover .employee-img {
  display: none;
}

.salary-adjustment-action {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  border: solid 1px #d9dadb;
  float: left;
  padding: 16px 4px;
  cursor: pointer;
}

.data-uploader {
  width: 50%;
  text-align: center;
  border-radius: 4px;
  border: dashed 1px #d9dadb;
  margin-top: 16px;
  padding: 16px 4px;
  cursor: pointer;
}

.employee-table {
  padding-top: 16px;
  border: 1px solid #d9dadb;
  max-height: 80vh;
  overflow-y: auto;
  height: 80vh;
}

.employee-row {
  border-radius: 4px;
  padding: 4px 16px;
  cursor: pointer;
}

.employee-row-selected {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 4px 16px;
  cursor: pointer;
}

#salarysheet,
#payroll-overview {
  position: absolute;
}

#salarysheet {
  left: 150%;
  width: 100%;
  -webkit-transition: left 1s;
  -moz-transition: left 1s;
  -o-transition: left 1s;
  transition: left 1s;
}

#payroll-overview {
  left: 0px;
  width: 100%;
  -webkit-transition: left 1s;
  -moz-transition: left 1s;
  -o-transition: left 1s;
  transition: left 1s;
}

#salary-sheet-btn {
  display: inline-block;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  width: 160px;
}

#payroll-overview-btn {
  display: none;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  width: 160px;
}

.payroll-option {
  width: 80px;
  border-radius: 4px;
  background-color: #f7f8f8;
  padding: 7px 0px;
  text-align: center;
  border: 1px solid #ddd;
}

.payroll-timeline {
  margin-bottom: 32px;
}

.payroll-timeline-element {
  width: 100px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #f7f8f8;
  margin-right: 16px;
  margin-bottom: 24px;
  padding: 20px;
  float: left;
  span {
    color: #636465 !important;
  }
}

.payroll-timeline-element-selected {
  width: 100px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.24), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #1e8ced;
  margin-right: 16px;
  margin-bottom: 24px;
  padding: 20px;
  float: left;
  span {
    color: #fff !important;
  }
}

// .attendance-data-payroll
// .rbc-month-row, .rbc-calendar{
// border: 0px !important;
// }

.attendance-data-payroll .rbc-month-view {
  // border: 0px !important;
  box-shadow: none !important;
}

// .attendance-data-payroll
// .rbc-day-bg + .rbc-day-bg{
//   border-left:0px solid #DDD !important;
// }

// .attendance-data-payroll .rbc-header{
//   border-bottom:0px !important;
// }

// .attendance-data-payroll .rbc-header + .rbc-header {
//   border-left:0px solid #DDD !important;
// }

.attendance-data-payroll .rbc-date-cell.rbc-current {
  background: transparent !important;
}

.attendance-data-payroll .rbc-date-cell.rbc-current a {
  color: rgba(0, 0, 0, 0.87) !important;
}

.attendance-data-payroll .rbc-toolbar {
  background: #fff !important;
}

.attendance-data-payroll .rbc-toolbar1 {
  background: #166ab3 !important;
}

.attendance-data-payroll .calender-head {
  color: #010709 !important;
  font-weight: bold !important;
}

.attendance-data-payroll .rbc-date-cell.rbc-current::after {
  background: #fff !important;
}

.attendance-data-payroll .rbc-row .rbc-date-cell:hover {
  background: transparent !important;
}

.attendance-data-payroll .rbc-event {
  top: 3px !important;
  margin-left: 2px !important;
}

.present-event,
.leave-event,
.weekoff-event,
.holiday-event,
.presentwork-event {
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 8px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  width: 8px;
  height: 19px;
  -webkit-transition: width 0.2s ease-in-out; /* For Safari 3.1 to 6.0 */
  transition: width 0.2s ease-in-out;
  overflow: hidden;
}

.present-event {
  background: #1e8ced;
}

.present-event:hover {
  width: 53px;
}

.leave-event {
  background: #faa500;
}

.leave-event:hover {
  width: 40px;
}

.weekoff-event {
  background: #d9dadb;
  color: #010709;
  width: 16px;
}

.weekoff-event:hover {
  width: 64px;
}

.holiday-event {
  background: #f796c0;
  width: 11px;
}

.holiday-event:hover {
  width: 52px;
}

.presentwork-event {
  background: #b596f7;
  width: 19px;
}

.presentwork-event:hover {
  width: 106px;
}

.event-p-show,
.event-l-show,
.event-w-show,
.event-h-show,
.event-ph-show {
  display: block;
  -webkit-transition: display 0.2s ease-in-out;
  transition: display 0.2s ease-in-out;
  opacity: 1;
}

.event-p-hide,
.event-l-hide,
.event-w-hide,
.event-h-hide,
.event-ph-hide {
  opacity: 0;
  -webkit-transition: display 0.8s ease-in-out;
  transition: display 0.8s ease-in-out;
}

.present-event:hover .event-p-show,
.leave-event:hover .event-l-show,
.weekoff-event:hover .event-w-show,
.holiday-event:hover .event-h-show,
.presentwork-event:hover .event-ph-show {
  opacity: 0;
  display: none;
}

.present-event:hover .event-p-hide,
.leave-event:hover .event-l-hide,
.weekoff-event:hover .event-w-hide,
.holiday-event:hover .event-h-hide,
.presentwork-event:hover .event-ph-hide {
  opacity: 1;
}

.attendance-data-payroll .rbc-row-content {
  height: 48px;
}

.attendance-data-payroll .rbc-calendar {
  width: 80%;
}

.attendance-data-payroll .rbc-date-cell {
  min-height: 48px !important;
  line-height: 48px !important;
}

.payroll-dashboard-btn button {
  font-size: 14px !important;
}

.payroll-compliance-outer{
  background-color: #EDF5FD;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
}

// .payroll-compliance-outer::after{
//   content: '';
//   position: absolute;
//   top: 0; right: 0; bottom: 0; left: 0;
//   z-index: -1;
//   margin: 8px; /* !importanté */
//   border-radius: inherit; /* !importanté */
//   background: linear-gradient(to right, red, orange);
// }