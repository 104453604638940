@import "../abstracts/mixins";
@import "../abstracts/variable";
/*********** Navigation **********/

.right-navigation {
  right: 0;
  position: fixed !important;
  width: 48px;
  background: #ffffff;
  border-left: 1px solid #dcdcdc;
  height: 100vh;
  z-index: 1201 !important;
}

.right-menu-item {
  width: 24px !important;
  margin: 0 auto !important;
  padding: 0px 0px 0px 0px !important;
  height: 28px !important;
  &:hover {
    background-color: transparent !important;
  }
}

.heltext-click-icon {
  font-size: 20px;
  margin-right: 5px;
  height: 24px;
  width: 24px;
  text-align: center;
  background: #fafafa;
  margin-top: 18px;
  cursor: pointer;
  border-radius: 50% 0 0 50%;
  color: #166ab3;
  span {
    color: #166ab3;
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
}

.nav-top-fixed {
  position: fixed;
  height: 100%;
}

.nav-top-fixed1 {
  top: 96px;
  width: 100%;
}

.nav-left {
  grid-column-start: 2;
}

.navsidebarsection-height::-webkit-scrollbar,
.navsidebarsection-height1::-webkit-scrollbar,
.content-outer-wrapper::-webkit-scrollbar {
  background-color: transparent;
}

.navsidebarsection-height::-webkit-scrollbar-thumb,
.navsidebarsection-height1::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

/*********** light theme Navigation **********/

.top-navbar {
  // position: fixed;
  // z-index: 1201;
  padding-left: 250px;
  -webkit-transition: padding 0.2s ease-out; /* For Safari 3.1 to 6.0 */
  transition: padding 0.2s ease-out;
}

.top-navbar-closed {
  padding-left: 64px;
  -webkit-transition: padding 0.2s ease-in; /* For Safari 3.1 to 6.0 */
  transition: padding 0.1s ease-in;
}

.layout-container {
  // display: flex;
  // flex-direction: column;
}

.content-wrapper {
  flex-wrap: nowrap !important;
}

.top-header {
  height: 56px;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  z-index: 1201;
  position: relative;
}

.content-outer-wrapper {
  // max-width: 1140px !important;
  max-width: 1048px !important;
  margin: 0 auto;
  // padding: 30px 64px;
  padding: 32px 31px;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
  // max-height: 85vh;
  // overflow-y: auto;
  //overflow-x: hidden;
  // flex-grow: 1;
  // height: 85vh;
}

.content-outer-wrapper-with-tab {
  max-width: 1140px;
  margin: 0 auto;
  padding: 80px 31px 30px 31px;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
  // overflow-x: hidden;
  // max-height: 75vh;
  // overflow-y: auto;
  // flex-grow: 1;
  // height: 75vh;
}

.content-outer-wrapper::-webkit-scrollbar,
.content-outer-wrapper-with-tab::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.nav-list-home {
  width: 100%;
}

.nav-list {
  background-color: transparent !important;
  border-left: 4px solid transparent !important;
  border-radius: 2px;
  color: $midnight !important;
}

.nav-list-item-column {
  flex-direction: column;
  background-color: transparent !important;
}

.nav-list-item-row {
  flex-direction: row;
  background-color: transparent !important;
  border-left: 4px solid transparent !important;
  border-radius: 2px !important;
  width: 100%;
}

.nav-list-item-row-active {
  color: #1e8ced !important;
  border-left: 4px solid #1e8ced !important;
  .list-item-icon {
    svg {
      fill: #1e8ced !important;
    }
  }
}

.nav-list-item-row-active .list-item-text {
  // color: #1E8CED !important;
  color: #1e8ced !important;
}

.nav-home:hover {
  .list-item-text span,
  .list-item-icon {
    color: #1e8ced !important;
    font-size: 14px;
  }
}

.nav-list:hover {
  .list-item-text span {
    //color: #166ab3 !important;
    color: inherit !important;
  }
  .list-item-icon {
    color: #1e8ced !important;
  }
}

.nav-home:hover .nav-list-item-row,
.nav-list:hover {
  border-left: 4px solid #1e8ced !important;
}

.list-item-text {
  padding: 0 4px !important;
  color: rgba(1, 7, 9, 0.7) !important;
  span {
    font-size: 14px;
    color: inherit !important;
  }
}

.list-item-icon {
  margin-right: 4px !important;
  svg {
    fill: #010709;
  }
}

.nav-list-hide {
  display: none !important;
  width: 250px;
}

.nav-list-show {
  display: block;
  width: 250px;
}

.nav-home {
  cursor: pointer;
}

.nav-footer {
  position: fixed;
  bottom: 0;
  width: 250px;
  transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  border-top: 1px solid #edefef;
  background: #f7f8f8;
}

.width64 {
  width: 64px;
}

.width250 {
  width: 250px;
}

.nav-footer-list-horizontal {
  display: flex;
  flex-direction: row;
  border-top: solid 1px #edefef;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.nav-footer-list-vertical {
  display: flex;
  flex-direction: column;
  border-top: solid 1px #edefef;
}

.nav-footer-list-item {
  background-color: transparent !important;
  flex: 1;
  // padding: 10px 0 !important;
  padding: 0px 0 !important;
}

.nav-footer-list-item-active {
  background-color: transparent !important;
  flex: 1;
  // padding: 10px 0 !important;
  padding: 0px 0 !important;
  svg {
    fill: #166ab3 !important;
  }
  .navbar-list-item-li-text {
    color: #166ab3 !important;
  }
}
.nav-footer-list-horizontal .nav-footer-list-item:first-child {
  margin-left: 4px !important;
}

.nav-footer-list-horizontal .nav-footer-list-item-active:first-child {
  margin-left: 4px !important;
}

.footer-list-item-icon {
  margin: 0 auto !important;
  img {
    fill: #545556;
  }
}

.nav-footer-list-item:hover .footer-list-item-icon svg {
  fill: #1e8ced;
}

.clock-in-out {
  // display: flex;
  margin-bottom: 18px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
  span {
    // flex:1;
    // font-size: 12px;
    button {
      padding: 8px;
      width: 100%;
    }
  }
}

.clockintime {
  display: block;
  text-align: center;
  margin-bottom: 6px;
  color: #010709;
  height: 16px;
}

.popper-menu-main {
  z-index: 1201;
  display: flex;
  position: absolute;
  transform: translate3d(146px, 5px, 0px);
  top: 20px !important;
  will-change: transform;
  left: -170px !important;
  .notification-menu-list {
    width: 340px;
    height: 464px;
    text-align: center;
    .popper-menu-border-div {
      width: 100%;
      height: 1px;
      position: relative;
      top: 17px;
      background: #d9dadb;
      z-index: 0;
    }
    .notification-header {
      width: 42px;
      position: relative;
      top: 5px;
      height: 18px;
      z-index: 1;
      background-color: #fff;
      padding-left: 10px;
      padding-right: 10px;
      margin: 10px auto 0 auto;
      font-size: 14px;
      font-weight: bold;
      color: rgba(1, 7, 9, 0.35);
      overflow: hidden;
    }

    .notification-empty {
      text-align: center;
      height: 360px;
      img {
        margin-top: 80px;
      }
      .main-header {
        display: block;
        height: 28px;
        font-family: Muli;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $midnight;
      }
      .sub-header {
        display: block;
        margin: 10px auto;
        width: 312px;
        height: 46px;
        opacity: 0.7;
        font-family: Muli;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: center;
        color: $midnight;
      }
      .showall {
        display: block;
        position: absolute;
        bottom: 0px;
        left: 95px;
        margin-bottom: 16px;
      }
    }
  }
}
.menu-bottom-list-items {
  background-color: #f7f8f8;
  // width: 360px;
  height: 34px;
  span {
    display: flex;
    // change justify-content to space-between when clear button is active
    justify-content: flex-end;
    margin-left: 10;
    margin-right: 10;
    span {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.popper-menu-paper {
  display: flex;
  padding: 10px;
  text-align: center;
}
.popper-menu-item {
  flex: 1;
  text-align: center;
  padding: 10px;
  color: #545556;
  font-size: 12px;
}
.popper-menu-item-active {
  flex: 1;
  text-align: center;
  padding: 10px;
  color: #057cff;
  font-size: 12px;
}

.popper-menu-item-icon {
  display: block;
  margin: 0 auto;
  margin-bottom: 2px;
  cursor: pointer;
}

.element-show {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.6, 1) 200ms;
}

.element-hide {
  opacity: 0;
}

.notification-list-items {
  border-bottom-style: dashed;
  border-bottom: 2px black;
  .notification-ul {
    padding-left: 16px;
    padding-right: 16px;
    height: 257px;
    overflow-y: scroll;
    scroll-behavior: smooth;

    .notification-ul-icon {
      margin-right: 0px !important;
    }
    .divder {
      margin-left: 34px;
      margin-right: 34px;
    }
  }
  .notification-grid {
    font-size: 14px;
    padding: 2px;
    &:nth-child(1) {
      background-color: yellowgreen;
      text-align: center;
      display: flex;
      font-size: 14px;
      border-bottom-style: 2px solid dashed;
      justify-content: center;
      align-items: center;
    }
    &:nth-child(3) {
      background-color: yellowgreen;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.arrowIconsvg {
  width: 24px;
  border-radius: 50%;
  background-color: #1e8ced;
  height: 24px;
  margin-top: 8px;
  svg {
    object-fit: contain;
    margin-top: 8px;
  }
}

.notification-item {
  border-bottom: 1px dashed rgba(151, 151, 151, 0.2);
  padding-left: 0px !important;
  padding-right: 0px !important;
  align-items: flex-start !important;
}

.divider-text {
  z-index: 0;
  .span {
    z-index: 1;
  }
}

.apps-thumbnail {
  color: #000;
  font-size: 12px;
  display: block;
  text-align: center;
}

.apps-thumbnail-wrapper {
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.apps-thumbnail-wrapper:hover {
  border: 1px solid #8ec5f6;
  box-shadow: 0px 2px 4px rgba(1, 7, 9, 0.16);
}

.notification-scroll {
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

.menuWrapper .material-icons {
  color: #fff;
}

.MuiExpansionPanel-rounded:last-child,
.MuiExpansionPanel-rounded:first-child {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
