@import "../abstracts/mixins";
@import "../abstracts/variable";
$i: unquote("!important");

.common-menubar-content {
  box-shadow: none $i;
  .common-menubar-tabs {
    min-height: 40px !important;
  }
  .common-menubar-tabs-content {
    @include heightWidth($height: 40px, $min-height: 40px);
    margin-left: 30px;
    &:nth-child(1) {
      margin-left: 32px;
    }
  }
}
.common-menubar-breadcrumb-main {
  min-height: 40px $i;
  nav {
    @include heightWidth($height: 40px, $min-height: 40px);
    ol {
      @include heightWidth($height: 40px, $min-height: 40px);
      @include padding(
        $padding-top: 10px,
        $padding-bottom: 10px,
        $padding-left: 30px,
        $padding-right: 10px
      );
    }
  }
}

.config-img {
  position: relative;
  top: 3px;
}

.common-menubar-header {
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 3;
}

.common-menubar-header-scrollable {
  position: sticky;
  top: 56px;
  background: #fff;
  width: 100%;
  z-index: 3;
}

.common-menubar-header-sticky {
  position: sticky;
  top: 88px;
  background: #fff;
  width: 100%;
  z-index: 3;
}

.common-menubar-header-scrollable .MuiTabs-fixed,
.drawer-tabs-scrollable .MuiTabs-fixed {
  height: 48px !important;
}
.common-menubar-header-scrollable .MuiTabs-fixed .MuiTab-root,
.drawer-tabs-scrollable .MuiTabs-fixed .MuiTab-root {
  height: 48px !important;
}
.common-menubar-header-scrollable .MuiTabs-fixed:hover {
  overflow-x: auto !important;
}
.drawer-tabs-scrollable .MuiTabs-fixed:hover {
  overflow-x: auto !important;
}
.common-menubar-header-scrollable .MuiTabs-fixed::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.drawer-tabs-scrollable .MuiTabs-fixed::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.common-menubar-header-scrollable .MuiTabs-fixed::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}
.drawer-tabs-scrollable .MuiTabs-fixed::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.common-menubar-tabs-content {
  text-transform: capitalize !important;
  font-weight: bold !important;
  color: #035ec1 !important;
}
