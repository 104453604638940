.e-input-group {
  .e-clear-icon,
  &.e-control-wrapper .e-clear-icon {
    padding-top: 4px !important;
  }
}

.mui-textfield {
  background: #fff;
}

.mui-textfield input {
  font-size: 14px !important;
  padding: 11.7px 12px !important;
  font-family: "Muli", sans-serif !important;
}

.date-time-picker .e-datetime-wrapper {
  height: 44px !important;
}

.date-time-picker .e-datetimepicker {
  padding: 0px !important;
}

.form-dropdown input {
  padding: 4px 0px !important;
}

.css-151xaom-placeholder {
  font-size: 14px !important;
}

.dynamic-form-number-input input {
  font-size: 14px !important;
  padding: 11.7px 12px !important;
  font-family: "Muli", sans-serif !important;
  background: #fff !important;
}

.dynamic-form-input-error {
  border-color: #f44336 !important;
}

.dynamic-form-date {
  background: white;
}

.dynamic-form-date input {
  font-size: 14px !important;
  font-family: "Muli", sans-serif !important;
}

.dynamic-form-date .e-date-wrapper {
  width: 98% !important;
  z-index: 0 !important;
}

.dynamic-form-date span {
  z-index: 0 !important;
}

.dynamic-form-checkbox {
  padding: 0 !important;
  float: left;
}

.Mui-checked {
  color: #035ec1 !important;
}

.bulkinputs {
  .e-input-group:not(.e-float-icon-left),
  .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    height: 17px;
  }
}

.invite-team-tel-input .react-tel-input .country-list {
  right: -145px;
}

.invite-team-tel-input .react-tel-input {
  width: 100% !important;
}

.react-tel-input input {
  height: 38px !important;
}
