.business-config-layout-container{
  /* padding: 0 0px 0 70px; */
  padding: 0px;
}
.pre-loader{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  font-size: 18px;
}

@media  (max-width: 920px) {
  .business-config-layout-container{
    /* padding: 0 41px 0 70px; */
    padding: 0px;
  }
}
