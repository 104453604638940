.sort-data{
  height: 28px;
  border-radius: 4px;
  background-color: #edf5fd;
  color: #166ab3;
  padding: 4px 8px 0px 8px;
  cursor: pointer;
  text-align: center;
    display: table;
    min-width: 80px;
    margin-right: 8px;
}

.sort-arrow{
  height: 28px;
  border-radius: 4px;
  background-color: #edf5fd;
  color: #166ab3;
  padding: 4px 8px 0px 8px;
  cursor: pointer;
  text-align: center;
  width: 32px;
}

.poper-bottom{
  top:60px !important;
}

.folder-thumb,.folder-thumb-active{
  width: 160px;
  height: 92px;
  border-radius: 4px;
  border: solid 2px transparent;
  padding-top: 16px;
  text-align: center;
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
}

.folder-thumb-wrapper{
margin-bottom: 32px;
}

.file-thumb, .file-thumb-active{
  width: 160px;
  height: 136px;
  border-radius: 4px;
  border: solid 2px transparent;
  text-align: center;
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
}

.folder-thumb-active, .file-thumb-active{
  border: solid 2px #1e8ced;
}

.file-name-wrapper{
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 6px 0px;
}

.file-actions{
  position: absolute;
  bottom: 40px;
  background: rgba(1, 7, 9, 0.7);
  padding: 4px 8px;
  border-radius: 40px;
  right: 4px;
}

.play-icon{
  position: absolute;
  bottom: 40px;
  top:0px;
  right: 0px;
  left:0px;
  background: rgba(1, 7, 9, 0.7);
  padding: 4px 4px;
  border-radius: 40px;
  width: 24px;
  height: 24px;
  margin: auto;
}

.folder-name{
  margin-top: 8px !important;
  display: block;
}

.folder-name-wrapper{
  height: 34px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.folder-name-wrapper .mui-textfield input{
  padding: 8.7px 12px !important;
}

.selcted-employees-list{
  background: #f7f8f8;
  height: 85vh;
  padding: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #555 transparent;
}

.activity-log-item{
border-bottom: solid 1px #d9dadb;
height: 40px;
padding: 8px 0px;
}

.uploader{
  width: 250px;
  border-radius: 4px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.37);
  background-color: #fff;
  text-align: center;
  padding: 4px 16px;
  position: fixed;
  right: 64px;
  bottom: 64px;
}