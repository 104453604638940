/************* Approval WorkFlow ********/

.depedent-select-list {
  border-top: 2px solid #0000002b !important;
  padding: 15px 15px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  cursor: pointer;
}

.depedent-select-list-head {
  font-size: 14px;
  font-weight: normal;
  text-align: justify;
  color: #166ab3;
  margin-bottom: 8px;
  margin-top: 0px;
}

.not-selected {
  height: 16px;
  width: 16px;
  border: 1px solid #0000005c;
  float: right;
  border-radius: 50px;
}

.bank-details-viewpage {
  word-break: break-word;
}

.not-selected-box {
  height: 16px;
  width: 16px;
  border: 1px solid #0000005c;
  float: right;
}

.selected-box {
  fill: #178de7;
}

.add-label {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: justify;
  color: #060606;
  margin-left: 15px;
}

.add-label-active {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: justify;
  color: #166ab3;
  margin-top: 0px;
  margin-bottom: 8px;
}

.depedent-select-list[aria-expanded="true"] {
  background-color: #2ecaff29;
}

.MuiListIt-component li {
  &:active,
  &:focus,
  &:focus-within {
    background-color: #2ecaff29;
  }
}
