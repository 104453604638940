body p {
  font-size: 16px;
  font-family: "Muli", sans-serif !important;
  margin-bottom: 8px;
}

.border-solid-black {
  border: 1px solid #000 !important;
}
.border-bottom-a9a9a9 {
  border-bottom: #a9a9a9;
  border-bottom-width: 1px;
}
.border-err {
  border: 1px solid red !important;
}
.cursurpointer {
  cursor: pointer;
}

.clear-float {
  clear: both;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex;
}

.display-table {
  display: table;
}

.display-inline-table {
  display: inline-table;
}

.display-table-cell {
  display: table-cell;
}

.horizontal-middle {
  margin: 0 auto !important;
  display: block;
}

.vertical-middle {
  vertical-align: middle;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* height*/
.height-unset {
  height: unset;
}

.height100vh {
  height: 100vh;
}
.height55vh {
  height: 55vh;
}
.height60vh {
  height: 60vh !important;
}
.height70vh {
  height: 70vh;
}

.height80vh {
  height: 80vh;
}

.height100 {
  height: 100% !important;
}

.hide-element {
  display: none;
}

.opacity0 {
  opacity: 0;
}

.opacity1 {
  opacity: 1;
}

.letter-spacing0 {
  letter-spacing: 0;
}

/*position*/
.position-abs {
  position: absolute;
}

.position-rel {
  position: relative;
}

/*font weight*/
.t-100 {
  font-weight: 100;
}

.t-bold {
  font-weight: bold !important;
}

.t-300 {
  font-weight: 300;
}

.t-500 {
  font-weight: 500;
}

.t-700 {
  font-weight: 700;
}

/*text align*/
.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
  margin: 0 auto !important;
}

.text-align-right {
  text-align: right !important;
}

/* text size*/
.t-12 {
  font-size: 12px !important;
}
.t-10 {
  font-size: 10px !important;
}
.t-11 {
  font-size: 11px !important;
}
.t-13 {
  font-size: 13px !important;
}
.t-14 {
  font-size: 14px !important;
}

.t-16 {
  font-size: 16px;
}

.t-18 {
  font-size: 18px;
}

.t-20 {
  font-size: 20px !important;
}

.t-24 {
  font-size: 24px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-32 {
  line-height: 32px;
}

.two-line-div {
  line-height: 14px !important;
  height: 30px;
  overflow: hidden;
}

.floatright {
  float: right;
}

.floatleft {
  float: left;
}

/* background color*/
.bg-color-white {
  background-color: #fff;
}
.bg-color-f7f8f8 {
  background-color: #f7f8f8;
}
.bg-color-edf5fd {
  background-color: #edf5fd;
}

.bg-transparent {
  background-color: transparent !important;
}
.bg-color-dark-blue {
  background-color: #011833;
}

/*Text Colors*/
.text-color-white {
  color: #fff !important;
}

.text-color-blue {
  color: rgba(63, 140, 211, 0.87) !important;
}

.text-color-grey {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-color-black {
  color: #000 !important;
}

.text-color-a9a9a9 {
  color: #a9a9a9;
}

/*Margins*/
.margin-auto {
  margin: auto;
}

.margin4 {
  margin: 4px !important;
}

.margin5 {
  margin: 5px;
}

.margin10 {
  margin: 10px;
}

.margin20 {
  margin: 20px !important;
}

.margin30 {
  margin: 30px;
}

.margin11 {
  margin: 11px;
}

.margin15 {
  margin: 15px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-2 {
  margin-bottom: 2px !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-48 {
  margin-bottom: 48px;
}

.margin-bottom-minus-16 {
  margin-bottom: -16px !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-left-1 {
  margin-left: 1px !important;
}

.margin-left-2 {
  margin-left: 2px !important;
}

.margin-left-4 {
  margin-left: 4px !important;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-6 {
  margin-left: 6px !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.margin-left-14 {
  margin-left: 14px;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-16 {
  margin-left: 16px !important;
}

.margin-left-24 {
  margin-left: 24px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-left-32 {
  margin-left: 32px !important;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-left-120 {
  margin-left: 120px;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.margin-top-3 {
  margin-top: 3px !important;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-top-6 {
  margin-top: 6px !important;
}

.margin-top-7 {
  margin-top: 7px;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-32 {
  margin-top: 32px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-56 {
  margin-top: 56px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-top-64 {
  margin-top: 64px !important;
}

.margin-top-90 {
  margin-top: 90px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.margin-top-128 {
  margin-top: 128px !important;
}

.margin-right-0 {
  margin-right: 0px !important;
}

.margin-right-4 {
  margin-right: 4px !important;
}

.margin-right-6 {
  margin-right: 6px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.margin-right-12 {
  margin-right: 12px !important;
}

.margin-right-14 {
  margin-right: 14px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-24 {
  margin-right: 24px !important;
}

.margin-right-32 {
  margin-right: 32px !important;
}

.margin-right-40 {
  margin-right: 40px !important;
}

/*padding*/

.padding0 {
  padding: 0px !important;
}

.padding1 {
  padding: 1px !important;
}

.padding2 {
  padding: 2px;
}

.padding4 {
  padding: 4px;
}

.padding5 {
  padding: 5px !important;
}

.padding8 {
  padding: 8px !important;
}
.padding10 {
  padding: 10px !important;
}

.padding15 {
  padding: 15px;
}

.padding16 {
  padding: 16px !important;
}

.padding20 {
  padding: 20px !important;
}

.padding24 {
  padding: 24px !important;
}

.padding30 {
  padding: 30px;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-bottom-2 {
  padding-bottom: 2px !important;
}

.padding-bottom-4 {
  padding-bottom: 4px !important;
}

.padding-bottom-8 {
  padding-bottom: 8px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-16 {
  padding-bottom: 16px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-24 {
  padding-bottom: 24px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.padding-bottom-90 {
  padding-bottom: 90px;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-left-4 {
  padding-left: 4px !important;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-24 {
  padding-left: 24px !important;
}
.padding-left-28 {
  padding-left: 28px !important;
}
.padding-left-30 {
  padding-left: 30px;
}

.padding-left-32 {
  padding-left: 32px;
}

.padding-left-40 {
  padding-left: 40px !important;
}

.padding-left-64 {
  padding-left: 64px;
}

.padding-left-100 {
  padding-left: 100px;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-6 {
  padding-right: 6px !important;
}

.padding-right-8 {
  padding-right: 8px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-12 {
  padding-right: 12px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-right-24 {
  padding-right: 24px !important;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-40 {
  padding-right: 40px !important;
}

.padding-right-64 {
  padding-right: 64px !important;
}

.padding-right-100 {
  padding-right: 100px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-2 {
  padding-top: 2px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-5-5 {
  padding-top: 5.5px !important;
}

.padding-top-6 {
  padding-top: 6px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-12 {
  padding-top: 12px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-16 {
  padding-top: 16px !important;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-32 {
  padding-top: 32px !important;
}

.padding-top-35 {
  padding-top: 35px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-45 {
  padding-top: 45px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-top-90 {
  padding-top: 90px;
}

.padding-top-120 {
  padding-top: 120px;
}

/*width*/

.widthauto {
  width: auto !important;
}

.width90 {
  width: 90% !important;
}

.width50 {
  width: 50% !important;
}

.width52 {
  width: 52% !important;
}

.width55 {
  width: 55%;
}

.width58 {
  width: 58%;
}

.width70 {
  width: 70% !important;
}

.width61 {
  width: 61% !important;
}

.width80 {
  width: 80%;
}

.width84 {
  width: 84% !important;
}

.width30 {
  width: 30%;
}

.width10 {
  width: 10% !important;
}

.width20 {
  width: 20% !important;
}

.width24 {
  width: 24%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width40 {
  width: 40% !important;
}

.width50 {
  width: 50%;
}

.minwidth50 {
  min-width: 50% !important;
}

.minheight40 {
  min-height: 40px !important;
}

.width60 {
  width: 60% !important;
}

.width70 {
  width: 70%;
}

.width75 {
  width: 75%;
}

.width80 {
  width: 80% !important;
}

.width200 {
  width: 200px;
}

.width250 {
  width: 250px;
}

.width260 {
  width: 260px;
}

.width360 {
  width: 360px;
}
.width400 {
  width: 400px;
}

.width500 {
  width: 500px;
}

.width550 {
  width: 550px;
}

.width600 {
  width: 600px;
}

.width100 {
  width: 100% !important;
}

.width98 {
  width: 98% !important;
}

.width95 {
  width: 95% !important;
}

.width96 {
  width: 96% !important;
}

.width94 {
  width: 94% !important;
}

.width140 {
  width: 140px !important;
}

.no-border {
  border: none;
}

.no-border-bottom {
  border-bottom: 0px !important;
}
.no-outline {
  outline: none;
}
.sticky-top-cellheaders {
  position: fixed;
  top: 56px;
  z-index: 1;
}
.bgcolor-e0e0e0 {
  background: #e0e0e0;
}
.bgcolor-dodgerblue {
  background: #1e8ced !important;
}

.bgcolor-dodgerRed {
  background-color: #ffe2e5 !important;
  color: #f60038 !important;
}

.bgcolor-dodgerRed.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.color-grey {
  color: grey;
}

.form-main-header {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  font-size: 16px;
}

.form-sub-header {
  font-size: 14px;
  font-weight: bold;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  margin-top: 0px;
}

.form-input-label {
  font-size: 14px;
  font-weight: 400;
  color: #166ab3;
  margin-bottom: 8px;
  margin-top: 0px;
}

.leave-policy-error {
  display: block;
  margin-top: 4px;
}

.ActiveStep button span span svg {
  border: 2px solid #166ab3;
  border-radius: 15px;
  color: #fff !important;
}

.ActiveStep button svg text {
  font-weight: bold;
  fill: #000;
}

.text-color-red {
  color: red;
}

.bg-color-fdfdfd {
  background-color: #fdfdfd;
}
.bg-color-f7f7f7 {
  background-color: #f7f7f7;
}
.bg-color-f4f4f4 {
  background: #f4f4f4;
}

.no-shadow {
  box-shadow: 0 0 0 !important;
}
.z-index-1000 {
  z-index: 1000;
}
.z-index-10000 {
  z-index: 10000;
}
.disp-inline-flex {
  display: inline-flex;
}

.form-text-input input {
  width: 100% !important;
  padding: 10px;
}

.reason-div {
  background: #fff6e1;
  font-size: 12px;
  margin: 8px 0px;
  padding: 4px;
}

.header-centered-title {
  flex: 1;
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.flex-direction-column {
  flex-direction: column;
}

.hide-y {
  overflow-y: hidden;
}

.hide-div-scroll {
  overflow-y: auto;
}
.hide-div-scroll::-webkit-scrollbar {
  background-color: transparent;
}
.hide-div-scroll::-webkit-scrollbar-thumb {
  width: 0px;
}

.thin-scroll {
  scrollbar-width: thin;
  scrollbar-color: #545556;
}

.thin-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  -webkit-appearance: none;
  border-radius: 10px;
}
.thin-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #545556;
}

.thin-scroll-on-hover {
  scrollbar-width: thin;
  scrollbar-color: rgb(19, 18, 18);
  overflow-y: hidden;
  overflow-x: hidden;
}

.thin-scroll-on-hover::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  -webkit-appearance: none;
  border-radius: 10px;
}
.thin-scroll-on-hover::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.thin-scroll-on-hover:hover {
  overflow-y: auto;
}

.super-thin-scroll {
  scrollbar-width: thin;
  scrollbar-color: #555;
}

.super-thin-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
  -webkit-appearance: none;
  border-radius: 10px;
}
.super-thin-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.thin-scroll1 {
  scrollbar-width: thin;
  scrollbar-color: #f5f5f5 transparent;
}

.thin-scroll1::-webkit-scrollbar {
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.thin-scroll1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.thin-scroll2 {
  scrollbar-width: thin;
  scrollbar-color: #f5f5f5 transparent;
}

.thin-scroll2::-webkit-scrollbar {
  height: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.thin-scroll2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.overflow-y-auto {
  overflow-y: auto;
}

.object-fit-cover {
  object-fit: cover;
}

.log-drawer-label {
  span {
    height: 17px !important;
  }
}

.holds-the-iframe {
  background: url("../Gif/loader.gif") center center no-repeat;
  background-size: 100px 100px;
}

.rst__rowContents {
  padding-left: 16px !important;
}

.rst__rowTitleWithSubtitle {
  height: unset !important;
}

.rst__rowSubtitle {
  line-height: unset !important;
}

.bg-color-ffe2e5 {
  background: #ffe2e5;
}

.cursor-pointer {
  cursor: pointer !important;
}
