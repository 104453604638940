@import "../abstracts/variable";
@import "../abstracts/mixins";
.content-subwrapper {
  margin: 96px 78px 120px 82px;
}

.content-subwrapper3 {
  margin: 96px 30px 30px 82px;
}

.content-subwrapper1 {
  margin: 152px 30px 120px 82px;
}

.content-subwrapper2 {
  margin: 96px 78px 120px 82px;
}

.config-content-subwrapper {
  margin: 96px 30px 30px 82px;
}
.innerwrapperheader1 {
  padding: 20px 82px;
  background: #166ab3;
  height: 120px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.innerwrapper-full-width {
  margin: 0;
  width: 100%;
}

.innerwrapperheader2 {
  padding: 20px 82px;
  background: #166ab3;
  // height: 64px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.innerwrapper-page-header {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  z-index: 10;
}

///New top NavBar Css///
.navbar-main {
  z-index: 100;
  position: relative;
  flex-grow: 1;
  background-color: $mdc-theme-background;
  header {
    background-color: #fff !important;
    // box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.06);
    @include boxShadow(0px, 2px, 6px, 0, rgba(0, 0, 0, 0.06));
    height: 56px;
  }
  .navbar-toolbar {
    padding-left: 0px;
    padding-right: 0px;
    vertical-align: center;
    // justify-content:space-between;
    min-height: 56px !important;
    .navbar-list-item {
      // @include heightWidth($height:55px,$width:100%);
      margin-left: 0px;
      .navbar-list-item-ul {
        display: flex;
        height: inherit;
        padding: inherit;
        cursor: pointer;
        .navbar-list-item-li {
          padding-bottom: 0px;
          padding-top: 0px;
          border-left: 2px solid #f7f8f8;
          border-right: 2px solid #f7f8f8;
          background: transparent;
          &:hover {
            cursor: pointer;
          }
          .navbar-list-item-li-img {
            @include heightWidth(16px, 16px);
          }
          .navbar-list-item-li-text {
            @include heightWidth($height: 16px, $width: 100%);
            color: #010709;
            font-size: 14px;
            display: block;
          }
          .navbar-list-item-li-span {
            flex: 1;
            text-align: center;
            display: block;
          }
        }
      }
      .navbar-list-items-icon {
        margin-left: 22px;
      }
      .navbar-list-items.button {
        margin-left: 20px;
        padding: none !important;
      }
    }
    .subitems {
      display: flex;
      margin-right: 60px;
      position: absolute;
      right: 0;
      button {
        @include heightWidth(32px, 32px);
        span {
          position: absolute;
          top: 4px;
        }
      }
      .navigation-avatar {
        @include heightWidth(32px, 32px);
        margin-right: 4px;
      }
      .dropdownarrow {
        cursor: pointer;
        svg {
          margin-top: 2px;
          color: $mdc-theme-primarygrey;
        }
      }

      .navbar-notification {
        color: rgba(33, 32, 32, 0.82);
        @include heightWidth(20px, 20px);
        padding: 4px;
        margin-right: 16px;
        background-color: $BlackHaze;
        border-radius: 16px;
        border: 2px solid #f7f8f8;
        position: relative;
        cursor: pointer;
        .bell-icon {
          @include margin($margin-left: 4px, $margin-top: 2px);
          @include heightWidth(25px, 25px);
        }
        svg {
          fill: #1e8ced;
        }
        .navbar-notification-active-dot {
          display: block;
          position: absolute;
          top: -3px;
          right: 1px;
          width: 8px;
          height: 8px;
          border-radius: 16px;
          border: solid 4px #fff;
          background-color: #1e8ced;
        }
      }
    }
    .navigation-avatar {
      @include imageStyle($object-fit: cover);
    }
  }
}

.navbar-menu-button {
  display: block;
  @include heightWidth($height: 41px, $width: 46px);
  text-align: center;
  padding: 6px;
  margin-left: 22px;
  &:hover {
    cursor: pointer;
  }
  .navbar-menu-button-img {
    object-fit: none !important;
    margin: 0 auto;
    @include heightWidth(25px, 25px);
    display: block;
  }
  .navbar-menu-button-text {
    color: #010709;
    display: block;
    font-size: 14px;
  }
}
.profile-menu {
  top: 56px !important;
  ul {
    @include padding($padding-top: 2px, $padding-bottom: 5px);
  }
}
.navbar-menu-account {
  padding: 10px !important ;
  border-top: 1px solid #d9dadb !important;
  border-bottom: 1px solid #d9dadb !important;
  .menu-first-span {
    width: 100%;
    display: inline-block;
  }
  .menu-icon {
    @include margin($margin-right: 17px, $margin-left: 12px);
    position: relative;
    top: 2px;
  }
  .sub-icon {
    position: relative;
    right: 0;
    display: inline-block;
  }
}

.navbar-menu-config {
  // height: 30px;
  &:nth-child(1) {
    padding: 10px 14px !important;
  }
  .menu-first-span {
    width: 100%;
    display: inline-block;
  }
  .menu-icon {
    @include margin($margin-right: 17px, $margin-left: 12px);
    position: relative;
    top: 2px;
  }
  .config {
    position: relative;
    right: 0;
    display: inline-block;
  }
}
.navbar-menu-items {
  padding: 10px 16px 10px 16px !important;
  .menu-first-span {
    width: 100%;
    display: inline-block;
    img {
      object-fit: contain;
    }
  }
  .menu-icon {
    @include margin($margin-right: 16px, $margin-left: 6px);
    position: relative;
    top: 2px;
  }
  .sub-icon {
    position: relative;
    right: 0;
    display: inline-block;
  }
}
.navbar-profile-item {
  margin-top: -2px !important;
  background-color: #f7f8f8 !important;
  height: 56px !important;
  .menu-first-span {
    display: inline-block;
    margin-right: 10px;
  }
  .profile-heading {
    width: 110px;
    display: block;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
    line-height: 1em;
    margin-top: 14px;
  }
  .profile-sub-heading {
    position: relative;
    font-size: 0.9em;
    right: 0;
    display: inline-block;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 170px;
  }
}

.top-nav-log-wrapper {
  width: 250px;
  height: 56px;
  position: relative;
}

.top-nav-log-wrapper img {
  height: 32px;
  position: absolute;
  top: 0;
  bottom: 0px;
  margin: auto;
  left: 0;
  right: 0;
}

.page-header-name {
  color: $midnight;
  font-size: 19px;
  line-height: 29px;
  font-weight: bold;
  margin-bottom: 24px;
  display: block;
}

.page-header-name-light {
  color: rgba(1, 7, 9, 0.7);
  font-size: 19px;
  font-weight: $fontRegular;
  margin-bottom: 24px;
  display: block;
}

.page-subheader-name {
  color: $midnightLight;
  font-size: 14px;
  font-weight: $fontRegular;
  margin-bottom: 24px;
  display: block;
}

.notification-duration {
  color: $midnightLight;
  font-size: 16px;
  cursor: default !important;
  font-weight: $fontBold;
  display: block;
  margin-bottom: 8px;
  margin-left: 8px;
}

.notification-expansion-panel {
  margin: 0px 0px !important;
  box-shadow: none !important;
  border-bottom: dashed 1px $lightPeriwinkle;
  :hover {
    cursor: default !important;
  }
}

.notification-expansion-panel-summary {
  padding: 0 8px 0 8px !important;
  background: #fff;
  font-size: 14px;
  height: 32px;
  min-height: unset !important;
}

.notification-expansion-panel-summary:hover {
  background: #f7f8f8;
}

.notification-expansion-panel::before {
  height: 0px !important;
}

.notification-list {
  margin-bottom: 15px !important;
}

.notification-list .notification-expansion-panel:last-child {
  border-bottom: 0px !important;
}

.notification-type-icon {
  float: left;
  margin-right: 16px;
  height: 16px;
  margin-top: 1px;
}

.notification-type-icon svg {
  width: 16px;
}

.notification-center-outer {
  font-family: "Muli", sans-serif !important;
}

.notification-center-expansionpaneldetails span {
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.empty-notification-img {
  width: 20%;
  height: 20%;
  display: block;
  margin: 0 auto;
}

.notification-expansionpanel-details {
  border-bottom: dashed 1px $lightPeriwinkle;
  padding: 10px 10px 10px 60px !important;
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7);
  background: #f7f8f8;
}

.notification-authority {
  width: 24px;
  float: left;
  margin-right: 8px;
  margin-left: -16px;
  height: 24px;
}

.notification-authority-thumbnail {
  width: 100%;
  height: 100%;
  border: solid 2px #fff;
  border-radius: 20px;
  background: #fff;
}

.notification-authority-thumbnail-acceptor {
  border: solid 2px #18aca7;
  background: #18aca7;
}

.notification-authority-thumbnail-rejector {
  border: solid 2px #ff4052;
  background: #ff4052;
}

.arrow-btn {
  background: #1e8ced;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.custom-divider {
  height: 1px;
  width: 100%;
  background: #d8d8d8;
  position: relative;
  top: 12px;
  z-index: -1;
}

.type-heading {
  text-align: center;
  font-size: 14px;
  background: #fff;
  margin: 0 auto;
  display: block;
  width: 120px;
  color: rgba(1, 7, 9, 0.35);
}

.expense-type-outer {
  width: 304px;
  height: 464px;
  border-radius: 4px;
  padding: 8px 24px 8px 24px;
  border: solid 1px $lightPeriwinkle;
}

.expense-text-input input {
  padding: 10px !important;
  width: 80%;
}

.expense-text-input {
  width: 100%;
  position: relative;
}

.expense-category-input {
  width: 100%;
  position: relative;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.expense-category-input input {
  padding: 10px 16px !important;
  width: 60%;
}

.expense-type-add-btn {
  height: 16px !important;
  width: 16px !important;
  position: relative;
  margin-left: 16px;
  right: -260px;
  top: -35px;
  border-radius: 16px;
  background: rgb(30, 140, 237);
  color: rgb(255, 255, 255);
}

.expense-category-add-btn {
  height: 16px !important;
  width: 16px !important;
  position: relative;
  margin-left: 16px;
  right: -196px;
  top: -27px;
  border-radius: 16px;
  background: rgb(30, 140, 237);
  color: rgb(255, 255, 255);
}

.custom-expense-types-list-item,
.default-expense-types-list-item {
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 14px;
}

.expense-types {
  overflow-y: auto;
  max-height: 400px;
}

.transition-period-empty {
  background: transparent;
  margin-top: 8px;
  padding: 2px;
}

.transition-period {
  background: #82bdff;
  margin-top: 8px;
  padding: 2px;
}

.compensator-name {
  padding: 11px 8px;
  color: rgba(1, 7, 9, 0.7);
  font-size: 14px;
  cursor: pointer;
  float: left;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.compensator-name:hover {
  color: #1e8ced;
}

.all-select {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7);
  font-weight: bold;
}

.all-selected {
  font-size: 14px;
  color: rgb(1, 7, 9);
  font-weight: bold;
}

.select-label {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7);
  font-weight: 400;
  width: 85%;
  display: inline-block;
}

.selected-name {
  font-size: 14px;
  color: rgb(1, 7, 9);
  font-weight: 400;
}

.selected-div,
.selected-div1 {
  border: solid 1px #d9dadb !important;
  border-radius: 4px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-right: solid 1px #fff !important;
  font-size: 14px !important;
}

.selected-div .css-bg1rzq-control,
.selected-div1 .css-bg1rzq-control {
  border: 0;
}

.compensator-assignment-list {
  overflow-y: auto;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.warning-msg {
  font-size: 12px;
  color: rgba(1, 7, 9, 0.7);
  line-height: 1.25em;
  margin-bottom: 16px;
}

.default-expense-category {
  font-size: 14px;
}

.default-expense-category:hover {
  background: transparent !important;
}

.selected-div:first-child::after {
  content: " ";
  background-color: #fff;
  color: #000;
  position: absolute;
  right: -5px;
  top: -1px;
  width: 5px;
  height: 5px;
  border-top: 1px solid #d9dadb;
}

.selected-div1:first-child::after {
  content: " ";
  background-color: #fff;
  color: #000;
  position: absolute;
  right: -3px;
  top: 0px;
  width: 5px;
  height: 5px;
  border-top: 1px solid #d9dadb;
}

.default-expense-types-list-item:hover {
  background: transparent !important;
}

.expense-cycle-input input {
  padding: 10px;
  font-size: 16px;
  font-family: "Muli", sans-serif !important;
}

.expense-cycle-input {
  width: 194px;
}

.every-month-text-show {
  position: relative;
  margin-left: 8px;
  opacity: 1;
}

.every-month-text-hide {
  position: relative;
  margin-left: 8px;
  opacity: 0;
}

.transitionperiod-data {
  background: #82bdff;
  padding: 2px;
  margin-top: 8px;
}

.assigned-expense-category {
  width: 304px;
  height: 220px;
  border-radius: 4px;
  border: solid 1px #d9dadb;
  overflow-y: auto;
}

.assigned-expense-category::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  background-color: #555;
}

.assigned-expense-category::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.add-all-category {
  border-bottom: 1px solid #d9dadb;
  height: 19px;
  padding: 10px 24px;
}

.expenses-list {
  margin-top: 8px;
}

.expense-subcategory {
  padding: 8px 0px 8px 24px;
  clear: both;
}

.expense-category-data {
  padding-right: 24px;
}

.policy-expense-category-name {
  float: left;
  font-size: 16px;
  background: #fff;
  z-index: 1;
  position: relative;
  padding: 0 8px;
  color: #010709;
  font-weight: 700;
}

.expense-limit-switch {
  float: left;
  padding-top: 4px;
}

.expense-limit-outer {
  border: solid 1px #d9dadb;
  border-radius: 4px;
  width: 70%;
  height: auto;
  padding: 24px;
  margin: 30px 0px;
}

.category-name-wrapper {
  position: relative;
  top: -35px;
}

.category-name {
  font-size: 12px;
  font-weight: 700;
  background: #fff;
  padding: 0 8px;
}

.expense-subcategory-icon {
  float: left;
  margin-right: 8px;
}

.subcategory-details {
  padding: 0px 16px 0px 28px;
}

.icon-label {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.35);
  font-weight: 700;
  margin-left: 8px;
}

.icon-label1 {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.35);
  margin-left: 8px;
}

.icon-with-label {
  float: left;
  height: 16px;
}

.icon-with-label img {
  vertical-align: middle;
}

.light-blue-btn {
  background: #ebf4ff !important;
  height: 32px;
  color: #166ab3 !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  letter-spacing: 1.1px !important;
}

.passbook-item {
  border-bottom: 1px solid #f7f8f8;
  padding-bottom: 10px;
}

.expense-attachment-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.add-attachement-icon {
  height: 16px !important;
  width: 16px !important;
  border-radius: 16px;
  background: #1e8ced;
  color: #fff;
  margin-left: 16px;
  margin-top: 16px;
}

.add-attachment-new img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  left: 0;
}

.expense-selected-list-data {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-size: 14px;
}

.claim-request-data {
  border-bottom: solid 1px #d9dadb;
  padding-bottom: 8px;
  // background: #fff;
  margin-top: 12px;
  position: relative;
}

.claim-request-data-hover {
  background: #f7f8f8;
}

.claim-request-data-selected {
  background: #edf5fd;
}

.claim-request-attachment-img {
  object-fit: cover;
  width: 100%;
}

.view-icon-bg {
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: 0;
}

.view-icon {
  position: relative;
  left: 16px;
  top: 16px;
}

.dynamic-data-edit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: #fff;
}

.claim-request-data:hover .dynamic-data-edit {
  opacity: 1;
}

.add-attachment-thumb {
  width: 48px;
  height: 48px;
  border: 1px dashed #1e8ced;
  border-radius: 3px;
  cursor: pointer;
}

.add-attachment-thumb:hover .view-icon-bg {
  opacity: 1;
}

.add-attachment {
  width: 48px;
  height: 48px;
  border: 1px dashed #1e8ced;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
  float: left;
  margin-right: 8px;
}

.add-attachment:hover .view-icon-bg {
  opacity: 1;
}

.add-attachment-new {
  width: 90px;
  height: 133px;
  border: 1px dashed #1e8ced;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
  float: left;
  margin-right: 8px;
  margin-top: 5px;
}

.add-attachment-new:hover .view-icon-bg {
  opacity: 1;
}

.pending-request {
  padding-bottom: 24px;
  margin-bottom: 8px !important;
}

.profile-thumb {
  height: 32px;
  width: 32px;
}

.profile-thumb img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.profile-thumb-wrapper {
  height: 32px;
  width: 100%;
}

.profile-thumb-img {
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 4px;
  border: 2px solid #fff;
  padding: 2px;
  cursor: pointer;
}

.profile-thumb-img img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.profile-thumb-wrapper-with-border {
  height: 40px;
  width: 100%;
}

.profile-thumb-img-with-border {
  width: 32px;
  height: 32px;
  border: 2px solid #166ab3;
  border-radius: 20px;
  padding: 2px;
  float: left;
  margin-right: 4px;
  cursor: pointer;
}

.profile-thumb-img-with-border img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.border-bottom-light {
  border-bottom: 1px solid #edf5fd;
}

.dashed-border-bottom-light {
  border-bottom: 1px dashed rgba(217, 218, 219, 0.5);
}

.down-arrow-indicator {
  position: relative;
  top: -9px;
  left: 0px;
  font-size: 32px;
  color: #035ec1 !important;
}

.compensated-details {
  height: 78vh;
  overflow-y: auto;
  padding: 2px;
}

.compensated-details::-webkit-scrollbar,
.navsidebarsection-height1::-webkit-scrollbar,
.content-outer-wrapper::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  border-radius: 3px;
}

.compensated-details::-webkit-scrollbar-thumb,
.navsidebarsection-height1::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: black;
  border-radius: 100px;
}

.set-limit-outer {
  // max-height: 480px;
  overflow-y: auto;
}

.expansion-panel-rejected-expense::before {
  height: 0px !important;
}

// .non-editable-data:hover .icon-with-label{
//   opacity: 1;
// }

// .icon-with-label {
//   opacity: 0;
// }

.bg-light-blue {
  background: #edf5fd;
}

.bg-light-red {
  background: $OldLace;
}

.overflowy-hidden {
  overflow-y: hidden !important;
}

.empty-state-illustration {
  // transform: translateY(-50%);
  // position: relative;
  // top: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

// .faq-bot-button {
//   // bottom: 64px !important;
//   bottom: 104px !important;
//   right: 100px !important;
// }

.BeaconFabButtonFrame {
  //bottom: 84px !important;
  bottom: 200px !important;
  right: 20px !important;
}

.list-scrollable {
  height: 92vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f5f5f5 transparent;
}

.footer-btn-wrapper {
  position: fixed;
  left: 0px;
  background: white;
  bottom: 0px;
  padding: 10px;
  border-top: 1px solid rgb(212, 212, 212);
  z-index: 0;
  width: 100%;
}

.faqbot-overlay {
  z-index: 1300 !important;
}

.drawer-last-select .css-26l3qy-menu {
  position: relative;
}

.policy-center-expansion .MuiExpansionPanelSummary-content {
  width: calc(100% - 32px);
}
