.mui-textarea input {
  padding: 0px !important;
}
.mui-textarea textarea {
  padding: 0px !important;
  font-size: 14px;
  max-height: 100px;
  height: 100px !important;
  scrollbar-width: thin;
  scrollbar-color: #f5f5f5 transparent;
  overflow-y: auto !important;
}
.mui-textarea input,
textarea {
  padding: 0px !important;
}
.mui-textarea textarea:focus {
  border: 0px !important;
  outline: 0px !important;
}

.mui-textarea textarea::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  border-radius: 10px;
  -webkit-appearance: none;
}
.mui-textarea textarea::-webkit-scrollbar-track:hover {
  background: #555;
  cursor: pointer;
  border-radius: 10px;
}
.mui-textarea textarea::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  // background-color: #f5f5f5;
}
