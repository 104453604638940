
/*Added by Rohit for View Mode*/

.vmSubHeader {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.97;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.38);
}

.vmHeader {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.7px;
  color: #000000;
}

.vmMetaData {
  height: 32px;
  font-size: 18.3px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.2px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
}