/************** Extra Time Planner ***********/

.save-icon:hover {
  fill: green !important;
  border: 1px solid green !important;
}

.close-icon:hover {
  fill: red !important;
  border: 1px solid red !important;
}

.save-icon,
.close-icon {
  top: 0;
  right: 0;
  border: 1px solid #666;
  border-radius: 50px;
  padding: 0px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  fill: #166ab3;
  margin-top: 2px;
}

.save-icon:hover {
  background: green;
  path {
    fill: #fff;
  }
}

.close-icon:hover {
  background: red;
  path {
    fill: #fff;
  }
}

.shift-table td {
  padding: 4px 10px !important;
  &:first-child {
    width: 30%;
  }
  &:nth-child(2) {
    width: 10%;
  }
  &:nth-child(3),
  &:nth-child(4) {
    width: 20%;
  }
  &:nth-child(5) {
    width: 10%;
  }
}

.master-sh {
  margin-top: 28px;
}

.active-tab {
  color: #166ab3 !important;
  background: #fff !important;
}

.inactive-tab {
  color: #fff !important;
  background: #166ab3 !important;
}

.image-center {
  margin: 0 auto;
}

.weeklyofflog {
  width: 147px;
  height: 19px;
  font-family: Muli;
  color: #171717;
}

.banking-chkbox input {
  &[type="radio"],
  &[type="checkbox"] {
    height: 45px !important;
  }
}

.attendence-add-button {
  background: #166ab3;
  color: #fff;
  border-radius: 50%;
  height: 24px !important;
  width: 24px !important;
  position: absolute;
  left: 25px;
  margin-top: 0px;
  cursor: pointer;
}

.clock {
  padding: 13px !important;
  background-color: transparent;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.clocK:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.draftclosebutton .draftclosebutton-text,
.draftclosebutton1 .draftclosebutton-text {
  // s
  transition: 0.6s ease all;
  margin-right: 1px;
}

.draftclosebutton:hover .draftclosebutton-text,
.draftclosebutton1:hover .draftclosebutton-text {
  display: block;
  transition: 0.6s ease all;
}

.draftbutton {
  background: #67bf07;
  border-radius: 30px;
  color: #fff;
  padding: 15px;
  font-size: 16px;
  margin-left: -30px;
  display: -webkit-inline-box;
  transition: 0.6s ease all;
}

.permission-img-1 {
  height: 180px;
  width: 180px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50%;
}

.leavebalance-details-confirm {
  float: left;
  margin: 0px 4px 0px 4px;
}

.top-profile {
  max-width: 100% !important;
  flex-basis: unset !important;
  margin-bottom: 20px !important;
}

.planner-landing-img {
  width: 58%;
  float: right;
}

.e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover.e-today
  span.e-day {
  background-color: #166ab3 !important;
}

.e-calendar .e-content td {
  &.e-today.e-selected span.e-day {
    background-color: #166ab3 !important;
    border: 1px solid #166ab3 !important;
  }
  &.e-selected span.e-day {
    background-color: #166ab3 !important;
  }
  &.e-today span.e-day,
  &.e-focused-date.e-today span.e-day {
    border: 1px solid #166ab3 !important;
  }
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  color: #166ab3 !important;
}

.e-calendar .e-content td.e-today {
  span.e-day {
    color: #166ab3 !important;
  }
  &.e-selected span.e-day {
    color: #fff !important;
  }
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: #166ab3 !important;
}

.form-height {
  height: 100% !important;
  padding-top: 0px;
}

.viewmore-btn {
  width: 110px;
  margin: 0 auto !important;
  text-transform: capitalize !important;
  background-color: #fff;
  color: #166ab3 !important;
  display: block !important;
}

.applyLeaveIp > .css-vj8t7z {
  height: 45px;
}

.drawer-cancle-btn {
  position: relative;
  top: 5px;
  background: transparent;
  height: 24px;
  width: 24px;
  display: inline-block;
  border-radius: 4px;
  padding: 6px 6px;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  svg {
    fill: rgba(0, 0, 0, 0.54);
  }
  &:hover svg {
    fill: rgba(0, 0, 0, 1);
  }
}

.textdecorationnone {
  text-decoration: none !important;
  cursor: default !important;
  &:hover {
    text-decoration: none !important;
    cursor: default !important;
  }
}

.basic-container {
  // width: 100%;
  display: flex;
  margin: 30px;
  padding: 10px;
}

.EditCard {
  margin: 30px;
  height: 255px;
}

.UnEditCard {
  margin: 30px;
  height: 235px;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

/*********** Extra Time *************/

.extra_time_form {
  width: 80%;
}

.extra_time_wrapper {
  width: 60%;
  input {
    width: 100%;
    font-size: 16px;
    padding: 10px 0px 10px 5px;
  }
  textarea {
    width: 100% !important;
  }
}

.et-level2 {
  background-color: #f4f4f4;
  width: 94%;
  margin-left: 3%;
}

.et-level3 {
  background-color: #fbfbfb;
  width: 94%;
  margin: auto;
}

.et-level4 {
  background-color: #ffffff;
  width: 94%;
  margin-left: 3%;
}
