
.margin0 {
  margin: 0px !important;
}

.margin8{
  margin: 8px !important;
}

.nomargin, .no-margin {
  margin: 0 !important;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-right-16 {
  margin-right: 16px !important;
}
