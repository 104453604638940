
/*********** Leave Policy *************/

.leave_policy_form {
  width: 100%;
}

.leave_wrapper {
  width: 60%;
  input {
    width: 100%;
    font-size: 16px;
    padding: 10px;
  }
  textarea {
    width: 100% !important;
  }
}

.navsidebarsection-height {
  max-height: 78vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #011833;
  scrollbar-width: thin;
  scrollbar-color: #f5f5f5 transparent;
  &:hover {
    overflow-y: auto;
  }
}

.navsidebarsection-height1:hover {
  overflow-y: auto;
}
