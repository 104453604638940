@import "./../abstracts/variable.scss";

.section-header-outer {
  padding: 4px 4px;
  margin-bottom: 8px;
  display: table;
}

.section-header-extra-small {
  font-size: 12px;
  color: rgba(1, 7, 9, 0.7);
  display: block;
}

.section-header-extra-small-normal {
  font-size: 12px;
  color: rgba(1, 7, 9, 0.7);
  display: block;
}

.section-header-small {
  font-size: 14px;
  font-weight: bold;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-header-small-normal {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px;
  margin-bottom: 0px;
}

.call-out-wrapper {
  padding: 11px;
  display: table;
  box-sizing: border-box;
}
