.MuiListItemIcon-root {
  min-width: 24px !important;
}

.MuiRadio-root:hover{
background-color: transparent !important;
}

.custom-checkbox{
  margin-right: 8px !important;
  padding:0px !important
}

.custom-checkbox:hover{
  background-color:transparent !important;
}