$mdc-theme-primary: #166ab3;
$mdc-theme-secondary: #000000;
$mdc-theme-background: #fff;
$mdc-theme-surface: #fafafa;
$mdc-theme-on-primary: rgba(5, 124, 255, 1);
$mdc-theme-on-secondary: rgba(0, 0, 5, 1);
$mdc-theme-on-surface: rgba(250, 250, 250, 0.87);
$mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
$mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
$mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
$mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
$mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
$mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
$mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
$mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
$mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
$mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
$mdc-theme-text-primary-on-dark: white;
$mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
$mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
$mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
$mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
$mdc-theme-darkgrey: rgba(33, 32, 32, 0.82);
$mdc-theme-primarygrey: #545556;

// Color Palate Used For New Design//

$Denim: #166ab3;
$DodgerBlue: #1e8ced;
$Malibu: #8ec5f6;
$Selago: #edf5fd;
$BlackPearl: #010709;
$Abbey: #545556;
$Iron: #d9dadb;
$BlackHaze: #f7f8f8;
$WhiteSolid: #ffffff;
$Salem: #008985;
$Malachite: #18aca7;
$Narvik: #dcf2f1;
$HotToddy: #f86b07;
$Corn: #faa500;
$OldLace: #fef1d9;
$Tamarillo: #f60038;
$Thunderbird: #ff4052;
$Linen: #ffe2e5;
$midnight: #010709;
$cerulean: #035ec1;
$transparent: transparent;
$midnight: #010709;
$midnightLight: rgba(1, 7, 9, 0.7);
$lightPeriwinkle: #d9dadb;
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// font weights
$fontBold: 700;
$fontRegular: 400;

.primary-link-color {
  color: #166ab3;
}

.secondary-link-color {
  color: rgba(0, 0, 0, 0.87);
}

.bg-color-fff6e1 {
  background: #fff6e1;
}

.bg-color-light-red {
  background: $OldLace;
}

.ownertable table:last-child td {
  border-bottom: none !important;
}

a,
.secondary-color {
  color: rgba(0, 0, 0, 0.87) !important;
}

.position-static {
  position: static;
}

.inlindClass {
  display: inline-block !important;
}

.align-center {
  margin: 0 auto !important;
}

.inline-display {
  display: inline-block;
}

.inline-table {
  display: inline-table !important;
}

.caption {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}

.caption-error {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  padding-left: 2px;
  letter-spacing: 0.4px;
  color: #f60038;
}

.caption-helptest {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: rgba(1, 7, 9, 0.7);
}

.configuration-head {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.87);
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.acc-configuration-head {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 24px;
  height: 52px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.tab-heading {
  padding: 0px;
  font-size: 16px;
  text-transform: capitalize;
}

.chk-box-owner label {
  margin-right: 0px !important;
}
.viewLeads {
  min-height: 64px;
  margin-left: -30px !important;
  padding-top: 20px !important;
}
.hide-event {
  pointer-events: none;
}

.display-none {
  display: none !important;
  opacity: 0 !important;
  transition: "opacity .3s ease";
}

.fade-in-effect {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-out; /* For Safari 3.1 to 6.0 */
  transition: opacity 0.2s ease-out;
}

.resource-search {
  border-radius: 20px;
  border: solid 1px #d8d8d8 !important;
  height: 40px;
  input {
    padding-left: 40px;
  }
}

////Common Css Used In for all Components///Below Codes needs to be refactored////

.note-div {
  font-size: 12px;
  margin: 8px 0px;
  padding: 4px 8px;
}

.new-form-sub-header {
  font-size: 14px;
  font-weight: bold;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;
  margin-top: 4px;
}

.div-disabled {
  pointer-events: none;
}

.probation-days .seatlimit {
  margin-left: 0px;
}

.manageleadpage {
  margin-right: 24px;
}

.done-mark {
  background: green;
  color: white;
  border-radius: 15px;
  font-size: 15px !important;
}

.ActiveStep button span span svg {
  border: 4px solid #166ab373;
  border-radius: 15px;
}

.cycleList .MuiMenuItem-root-22 {
  width: 800px !important;
}

///Theme.css///
body p {
  font-size: 16px;
  font-family: "Muli", sans-serif !important;
  margin-bottom: 8px;
}

.border-solid-black {
  border: 1px solid #000 !important;
}

.cursurpointer {
  cursor: pointer;
}

.clear-float {
  clear: both;
}

.clear-left {
  clear: left;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex;
}

.display-table {
  display: table;
}

.display-inline-table {
  display: inline-table;
}

.display-table-cell {
  display: table-cell;
}

.vertical-middle {
  vertical-align: middle;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* height*/
.height-unset {
  height: unset;
}

.height100vh {
  height: 100vh;
}

.height100 {
  height: 100% !important;
}

.hide-element {
  display: none;
}

.letter-spacing0 {
  letter-spacing: 0;
}

/*position*/
.position-abs {
  position: absolute;
}

.position-rel {
  position: relative;
}
/*text align*/
.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
  margin: 0 auto !important;
}

.text-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}
.line-height-16 {
  line-height: 16px;
}

.line-height-32 {
  line-height: 32px;
}

.floatright {
  float: right;
}

.floatleft {
  float: left;
}

/* background color*/
.bg-color-white {
  background-color: #fff;
}

.bg-color-dark-light-blue {
  background-color: $Selago;
}

.bg-color-dark-blue {
  background-color: #011833;
}

/*Text Colors*/
.text-color-white {
  color: #fff !important;
}

.text-color-blue {
  color: #1e8ced !important;
}

.text-color-grey {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-color-a9a9a9 {
  color: #a9a9a9;
}
.no-border {
  border: none;
}

.no-border-bottom {
  border-bottom: 0px !important;
}
.no-outline {
  outline: none;
}
.sticky-top-cellheaders {
  position: fixed;
  top: 56px;
  z-index: 1;
}
.bgcolor-e0e0e0 {
  background: #e0e0e0;
}
.color-grey {
  color: grey;
}
.form-main-header {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  font-size: 16px;
}
.form-sub-header {
  font-size: 14px;
  font-weight: bold;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  margin-top: 0px;
}
.form-input-label {
  font-size: 14px;
  font-weight: 400;
  color: #166ab3;
  margin-bottom: 8px;
  margin-top: 0px;
}

.sub-heading {
  font-size: 14px;
  font-weight: 400;
  color: rgba(1, 7, 9, 0.7);
  margin-bottom: 8px;
  margin-top: 0px;
}

.input-label {
  font-size: 12px;
  font-weight: 400;
  color: rgba(1, 7, 9, 0.35);
  margin-bottom: 2px;
  margin-top: 0px;
}

.text-copy {
  font-size: 12px;
  font-weight: 400;
  color: rgba(1, 7, 9, 0.7);
  margin-bottom: 2px;
  margin-top: 0px;
  display: block;
}

.normal-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.normal-text-bold {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.medium-text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.medium-text-bold {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.medium-text-blue {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #1e8ced;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.medium-text-blue-bold {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #1e8ced;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.edit-icon-blue {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.edit-icon-blue img {
  height: 16px;
  width: 16px;
  position: relative;
  top: 3px;
}

.leave-policy-error {
  display: block;
  margin-top: 4px;
}

.ActiveStep button span span svg {
  border: 2px solid #166ab3;
  border-radius: 15px;
  color: #fff !important;
}

.ActiveStep button svg text {
  font-weight: bold;
  fill: #000;
}

.text-color-red {
  color: red;
}

.bg-color-f7f7f7 {
  background-color: #f7f7f7;
}
.bg-color-f4f4f4 {
  background: #f4f4f4;
}

.no-shadow {
  box-shadow: 0 0 0 !important;
}
.z-index-1000 {
  z-index: 1000;
}
.z-index-10000 {
  z-index: 10000;
}
.disp-inline-flex {
  display: inline-flex;
}

.form-text-input input {
  width: 100% !important;
  padding: 10px;
}

.display-none {
  display: none;
}

.reason-div {
  background: #fff6e1;
  font-size: 12px;
  margin: 8px 0px;
  padding: 4px;
}

.attendance-data-table td {
  padding: 4px 6px !important;
}

.attendance-data-table-head:first-child th {
  padding: 4px 6px 4px 10px !important;
}

.attendance-data-table tr td:first-child {
  padding: 4px 6px 4px 10px !important;
}
