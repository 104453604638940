.c-wrapper {
  max-width: 1140px;
  min-width: 320px;
  margin: 0 auto;
  position: relative;
}
.c-fluid-container {
  margin-left: -32px;
  margin-right: -32px;
  width: calc(100% + 64px) !important;
  padding: 32px;
}
.c-btn-primary-md-blue {
  background-color: #1e8ced !important;
  font-size: 16px !important;
  height: 40px;
}
.c-btn {
  color: #fff !important;
  box-shadow: none !important;
  font-weight: bold !important;
  font-family: "muli", sans-serif !important;
  padding: 6px 16px !important;
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  border: 0;
}
.c-text-center {
  text-align: center;
}
.c-t-bold {
  font-weight: bold;
}
.c-large-text-dark-secondary {
  font-size: 19px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.c-normal-dark {
  font-size: 16px;
  color: #010709;
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.c-normal-blue {
  font-size: 16px;
  color: #1e8ced;
  margin-top: 0px !important;
  margin-bottom: 32px;
}
.c-normal-dark-secondary {
  font-size: 16px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.c-small-dark-secondary {
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7);
  margin-top: 0px;
  margin-bottom: 16px ;
}
.c-input-label {
  font-size: 12px;
  font-weight: 400;
  color: rgba(1, 7, 9, 0.35);
  margin-bottom: 8px;
  margin-top: 0px;
}
.c-position-rel {
  position: relative;
}
.c-rounded-rectangle-paper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px rgba(217, 218, 219, 0.5);
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
}
.c-rounded-rectangle-div {
  border-radius: 4px;
  border: solid 1px #d9dadb;
  background-color: #fff;
  padding: 10px;
}
.c-thumbnails {
  display: flex;
  justify-content: center;
}
.c-uploader-wrapper {
  height: 64px;
  width: 300px;
  border-radius: 4px;
  border: dashed 1px #d9dadb;
  position: relative;
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;
}
.c-vertical-center {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.c-section-title {
  font-size: 32px;
  line-height: 38px;
  color: #010709;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 0;
}
.c-hero-container {
  background-image: linear-gradient(
      66deg,
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.7) 25%,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0)
    ),
    url(https://c.wallhere.com/photos/3c/e7/alphabet_inc_google_holding_company-998408.jpg!d);
  background-position: 50%;
  background-size: cover;
  height: 384px;
  display: flex;
  align-items: flex-end;
  padding: 64px;
  position: relative;
}
.c-search-box {
  border: 1px solid #dcdcdc;
  padding: 4px 12px;
  border-radius: 4px;
  height: 40px;
  background: #fff;
  cursor: text;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
}
.c-search-box input {
  border: 0px;
  outline: none;
  font-size: 14px;
}
.c-search-box svg {
  width: 24px;
  fill: #ccc;
  margin-top: 4px;
}
.c-go-to-bottom {
  width: 72px;
  height: 72px;
  background: rgb(30, 140, 237);
  border-radius: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-chatbutton {
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 80px;
  height: 80px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  z-index: 1111;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 7px 24px 0px;
  display: flex;
  justify-content: center;
}
.c-notification-dot {
  width: 12px;
  height: 12px;
  border-radius: 24px;
  border: 8px solid rgba(254, 241, 217, 0.7);
  background: rgb(250, 165, 0);
  right: -10px;
  position: absolute;
  top: -5px;
}
.c-MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.c-MuiGrid-item {
  margin: 0;
  box-sizing: border-box;
}
.c-MuiGrid-spacing-xs-3 {
  width: calc(100% + 24px);
  margin: -12px;
}
.c-MuiGrid-spacing-xs-3 > .c-MuiGrid-item {
  padding: 12px;
}
.c-MuiGrid-grid-xs-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
}
@media (min-width: 600px) {
  .c-MuiGrid-grid-sm-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width: 960px) {
  .c-MuiGrid-grid-md-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
  .c-MuiGrid-grid-md-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
  }
  .c-MuiGrid-grid-md-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
  }
  .c-MuiGrid-grid-md-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
  }
  .c-MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .c-MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }
  .c-MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
  }
}

.c-form-element input,
.c-form-element textarea {
  outline: none;
  border-radius: 4px;
  border: solid 1px #dcdcdc;
  height: 40px;
  padding: 4px 12px;
  margin-bottom: 16px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}
.c-form-element textarea {
  height: 60px;
}
.c-form-element input[type="radio"],
.c-form-element input[type="checkbox"] {
  height: 16px;
  width: 16px;
}
.c-form-element select {
  outline: none;
  border: 1px solid #dcdcdc;
  padding: 4px 12px;
  border-radius: 4px;
  height: 40px;
  background-color: #fff;
  width: 100%;
  font-size: 14px;
  color: rgba(1, 7, 9, 0.7);
}

.c-form-element .react-tel-input .form-control {
  width: 100% !important;
}

.c-form-element .thumbnail-tooltip {
  max-width: 114px;
  height: 80px;
  width: 114px;
}

.c-form-element .thumbnail-big {
  width: 112px;
  height: 80px;
}

.c-form-element .thumbnail-big-iconwrapper {
  top: -84px;
  width: 114px;
}

.c-form-element .thumbnail-big-iconwrapper span {
  bottom: -27px;
}

.c-margin-bottom-16{
margin-bottom:16px;
}

.c-margin-bottom-24{
  margin-bottom:24px !important;
  }

.c-margin-bottom-48{
  margin-bottom:48px !important;
  }
