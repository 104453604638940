/******** Probation Planner *******/

div[name="isnotifyemployee"][data-isexpanded="true"] {
  margin-bottom: 55px;
}

.probation-plan-page .probation-plan-submit {
  padding-top: 0px !important;
}

.probation-planner-drawer {
  padding: 15px 0px 0 15px;
  width: 320px;
}

.planner-right-drawer {
  width: 90%;
  padding: 20px;
}

.planner-datepicker {
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: hsl(0, 0%, 50%) !important;
  }
  input {
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      font-size: 16px;
      color: hsl(0, 0%, 50%) !important;
    }
  }
}
