.ql-editor,
.ql-container.ql-snow {
  height: unset;
  min-height: 100px;
}

.uploader-wrapper {
  height: 64px;
  border-radius: 4px;
  border: dashed 1px #d9dadb;
  position: relative;
  text-align: center;
}

.uploader-wrapper:hover {
  border: dashed 1px #1e8ced;
}

.page-layout {
  width: 78px;
  height: 112px;
  border: solid 1px #d9dadb;
  margin: 0px auto 8px auto;
  position: relative;
}

.page-margin-layout-outer {
  width: 80px;
  float: left;
  padding: 8px;
  border: solid 1px transparent;
  border-radius: 4px;
}

.selected-layout {
  border: solid 1px #1e8ced;
}

.selected-layout p {
  color: #1e8ced;
}

.line1,
.line3 {
  width: 78px;
  height: 1px;
  border-bottom: dashed 1px #1e8ced;
  position: absolute;
}

.line2,
.line4 {
  width: 0px;
  height: 112px;
  border-left: dashed 1px #1e8ced;
  position: absolute;
}

.previewline1,
.previewline3 {
  width: 100%;
  height: 1px;
  border-bottom: dashed 1px #1e8ced;
  position: absolute;
  z-index: 1;
}

.previewline2,
.previewline4 {
  width: 0px;
  height: 100%;
  border-left: dashed 1px #1e8ced;
  position: absolute;
  z-index: 1;
}

.header-img,
.footer-img {
  position: absolute;

  height: 54px;
  width: 100%;
}

.footer-img {
  bottom: 0;
}

.header-img {
  top: 0;
}

.letter-type {
  width: 92px;
  border-radius: 4px;
  border: solid 1px #d9dadb;
  text-align: center;
  padding: 4px 4px 6px 4px;
}

.letter-requested {
  border-bottom: solid 1px #d9dadb;
  padding: 16px 0px;
  position: relative;
}

.letter-requested p {
  color: rgba(1, 7, 9, 0.7);
}

.letter-requested:hover .letter-requested p {
  color: rgba(1, 7, 9, 1);
}

.white-overlay {
  width: 30%;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  background: -moz-linear-gradient(left, transparent 0%, #ffffff 50%);
  background: -webkit-linear-gradient(left, transparent 0%, #ffffff 50%);
  background: linear-gradient(to right, transparent 0%, #ffffff 50%);
}

.request-letter-btn {
  opacity: 0;
  border-radius: 20px !important;
  height: 24px !important;
  font-size: 12px !important;
  padding: 0px 16px 1px 16px !important;
  position: absolute !important;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
}

.letter-requested:hover .request-letter-btn {
  opacity: 1;
}

.unread-div {
  float: left;
  margin-top: 15px;
  margin-right: 16px;
  width: 8px;
  height: 8px;
}

.blue-dot {
  width: 8px;
  height: 8px;
  background-color: #1e8ced;
  border-radius: 20px;
}

.letter-box-row-outer {
  border-radius: 4px;
  display: table;
  width: 100%;
  border: 0px;
  box-shadow: none;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #f7f8f8;
}

.letter-box-row-outer:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(217, 218, 219, 0.5);
}

.letter-box-row p {
  color: rgba(1, 7, 9, 0.7) !important;
  font-size: 0.875rem;
}

.letter-box-row-outer:hover .letter-box-row p {
  color: rgba(1, 7, 9, 1) !important;
}

.letter-box-table-img {
  height: 32px;
  width: 32px;
  border-radius: 30px;
  border: solid 1px transparent;
  vertical-align: middle;
  padding: 2px;
}

.letter-box-row-outer:hover .letter-box-table-img {
  border: solid 1px #1e8ced;
}

.signature-type {
  width: 200px;
  height: 108px;
  padding: 16px 24px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
  margin-right: 32px;
  position: relative;
}

.pagelayout-file-uploader .thumbnail-big-wrapper {
  height: 100%;
  width: 100%;
}
