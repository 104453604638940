/************* BreadCum **********/

.breadcum {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.87);
  padding-right: 10px;
  padding-left: 10px;
}

.emp-code-generator-section .custom-prefix-divider:nth-child(4) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.imp-dates .e-input-group {
  height: 26px !important;
  padding: 8px !important;
  &.e-control-wrapper {
    height: 26px !important;
    padding: 8px !important;
  }
}

.emp-from-wrapper.imp-dates {
  .form-group .selectsearch {
    width: 430px !important;
    font-size: 16px;
  }
  .dependent-datepicker {
    width: 70% !important;
  }
  .form-group input {
    width: 410px !important;
    &[type="checkbox"] {
      width: 45px !important;
    }
  }
  .react-tel-input {
    input {
      width: 430px !important;
      height: 40px !important;
    }
    width: 71% !important;
  }
  .form-group textarea {
    width: 430px !important;
  }
}
