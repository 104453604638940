div[class*="MuiSnackbarContent-action-"] button {
  color: #fff;
}

div[class*="MuiSnackbarContent-root"] {
  max-width: 276px !important;
  min-width: 276px !important;
}

/* div[class*='MuiSnackbar-root'] {
  display: inline-table !important;
} */

div[class*="MuiSnackbarContent-action-"] button {
  color: #fff;
}

.MuiSnackbarContent-action {
  margin: 4px 0px 8px auto !important;
}

.MuiSnackbarContent-action button {
  font-weight: bold !important;
  text-transform: capitalize !important;
  letter-spacing: 0px !important;
}
