/*********** Shift Planner **************/

.shiftplanner-forms {
  .config-landing-content-container {
    padding: 0px !important;
  }
  hr {
    display: none;
  }
}

.colorpicker div {
  position: relative;
  overflow: hidden !important;
  padding: 0 !important;
  &[tabindex="0"] {
    height: 40px !important;
    width: 40px !important;
    line-height: 50px !important;
  }
}

.shiftTimingInput {
  height: 38px;
  padding: 0 8px;
  width: 25%;
  font-size: 16px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  background: white;
}

.shiftTimingInput-combine {
  width: 159px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  height: 40px;
}

input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

.shiftTimingInput {
  &:hover,
  &:active {
    border: 1px solid #166ab3;
  }
}

.shiftTimingDropDown {
  width: 80px;
  margin-left: 5px;
  .css-vj8t7z {
    height: 40px;
  }
}

.draftbutton-innerwrapper {
  width: 56px;
  height: 56px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.deletebutton-innerwrapper {
  position: fixed;
  bottom: 90px;
  right: 22px;
  transition: 0.6s ease all;
  height: 56px;
  width: 56px;
  z-index: 12;
}

.draftbutton-wrapper {
  position: fixed;
  bottom: 22px;
  right: 22px;
  transition: 0.6s ease all;
  cursor: pointer;
  z-index: 11;
  height: 200px;
  width: 56px;
}

.draftbutton-wrapper_optional {
  position: fixed;
  bottom: 30px;
  right: 10%;
  transition: 0.6s ease all;
  cursor: pointer;
  z-index: 11;
}

.draftclosebutton {
  border-radius: 50px;
  color: #000;
  right: 5%;
  font-size: 14px;
  height: 24px;
  width: 24px;
  position: fixed;
  bottom: 135px;
  position: absolute;
  padding: 1px 8px 1px 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #e6ecf1;
  display: -webkit-inline-box;
  transition: 0.6s ease all;
  cursor: pointer;
  overflow: hidden;
  display: none;
}

.draftclosebutton1 {
  border-radius: 50px;
  color: #000;
  right: 5%;
  font-size: 14px;
  height: 24px;
  width: 24px;
  position: fixed;
  bottom: 70px;
  position: absolute;
  padding: 1px 8px 1px 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #e6ecf1;
  display: -webkit-inline-box;
  transition: 0.6s ease all;
  cursor: pointer;
  overflow: hidden;
  display: block;
}

.draftclosebutton .draftclosebutton-text,
.draftclosebutton1 .draftclosebutton-text {
  transition: 0.6s ease all;
  margin-left: 1px;
  opacity: 0;
  font-size: 16px;
  float: left;
}

.draftclosebutton:hover .draftclosebutton-text,
.draftclosebutton1:hover .draftclosebutton-text {
  opacity: 1;
}

.draftclosebutton:hover,
.draftclosebutton1:hover {
  width: 78px;
  transition: 0.6s ease all;
}

.draftbutton {
  background: #67bf07;
  border-radius: 50px;
  color: #fff;
  padding: 15px;
  font-size: 16px;
  display: -webkit-inline-box;
  transition: 0.6s ease all;
  width: 56px;
  height: 56px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.draft-option {
  opacity: 0;
  transition: 0.6s ease all;
  margin-left: 30px;
  position: absolute;
  top: 16px;
  min-width: 110px;
}

.draft-option1 {
  opacity: 1;
  transition: 0.6s ease all;
  margin-left: 8px;
  position: absolute;
}

.draftbutton-wrapper:hover .draft-option1,
.draftbutton:hover .draft-option {
  opacity: 1;
  transition: 0.6s ease all;
}

.draftbutton-wrapper:hover {
  .draft-option {
    opacity: 1;
    transition: 0.6s ease all;
  }
  .draftbutton {
    width: 162px;
  }
}

// .editbutton {
//   background: #4177f6;
//   border-radius: 50px;
//   fill: #fff;
//   padding: 14px;
//   font-size: 16px;
//   margin-left: 0px;
//   width: 56px;
//   height: 56px;
//   transition: 0.6s ease all;
//   position: absolute;
//   right: 0;
//   bottom: 0;
// }

// .deletebutton1 {
//   background: #4177f6;
//   border-radius: 50px;
//   fill: #fff;
//   padding: 14px;
//   font-size: 16px;
//   margin-left: 0px;
//   width: 100%;
//   height: 56px;
//   transition: 0.6s ease all;
//   position: absolute;
//   right: 0;
//   bottom: 0;
// }

.deletebutton-innerwrapper:hover .delete-option {
  display: block;
}

.editbutton-svg {
  position: absolute;
}

.delete-option {
  display: none;
  color: #fff;
  position: absolute;
  margin-left: 28px;
  height: 30px;
  min-width: 176px;
}

.delete-option {
  .save-icon,
  .close-icon {
    border: 1px solid #fff;
  }
}

.editbutton:hover .edit-option {
  display: block;
}

.shiftcolor {
  height: 10px;
  width: 10px;
  position: absolute;
  margin-left: -15px;
  top: 1px;
}

.shiftcolor-large {
  height: 24px;
  width: 24px;
  margin-left: -10px;
}

.select-list-item {
  background-color: #2ecaff29 !important;
}

.shift-timing-list {
  color: #a3a3a3;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  padding: 0px !important;
  margin: 4px !important;
  position: relative;
  height: 56px;
  li {
    list-style-position: inside;
    text-align: center;
  }
}

.shift-planner-form {
  input,
  ul,
  .depedent-select-list {
    width: 70% !important;
  }
  select {
    width: 50% !important;
  }
  .selectsearch {
    width: 72% !important;
  }
}

.list-tabel {
  min-height: 48px;
  padding: 10px;
}
.scroll-div {
  overflow-x: scroll;
  float: right;
  overflow-y: hidden;
}

/********** Shift Planner Hot Tabel**********/

.headerData .weekdayslist {
  width: 100px !important;
  padding: 18px 11.1px !important;
}

#hot-display-license-info {
  display: none;
}

.shift-planner-date-filter {
  width: 100%;
  background-color: #edf5fd;
}

.daterange-flter-section {
  width: 200px;
  padding: 8px 0 8px 8px;
}

.shiftName {
  color: black;
  font-weight: normal;
  font-size: 10px;
}

.margin-right-142 {
  margin-right: 142px;
}
